import styled from "styled-components";

export const At = styled.span`
  margin: 12px 3px;
  height: 0px;
`;

export const ContentInput = styled.div`
  display: flex;
`;

export const ChannelIcon = styled.a`
  font-size: 22px;
  margin-right: 5px;
  color: #5271c4;
  cursor: pointer;
  && svg {
    margin-bottom: -3px;
  }
`;

export const FormContainer = styled.div`
  width: calc(100% - 82px);
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
