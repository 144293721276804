import React from "react";
import PropTypes from "prop-types";
import { FaCheck, FiAlertTriangle, FiInfo, FiFrown } from "react-icons/all";

import { Container, Box, Icon, Title, Text, Footer } from "./styles";
import { BtnPrimary } from "~/styles/elements";

function Alert({ open, title, text, type, buttons, onClose, onClick }) {
  return (
    open && (
      <Container>
        <Box>
          {type === "success" && (
            <Icon>
              <FaCheck />
            </Icon>
          )}
          {type === "alert" && (
            <Icon>
              <FiAlertTriangle />
            </Icon>
          )}
          {type === "info" && (
            <Icon>
              <FiInfo />
            </Icon>
          )}
          {type === "error" && (
            <Icon>
              <FiFrown />
            </Icon>
          )}
          <Title>{title}</Title>
          <Text>{text}</Text>
          <Footer>
            {buttons.map((b, i) => (
              <BtnPrimary
                key={`alert-btn-${b?.text}`}
                style={{
                  marginLeft: i > 0 ? 15 : 0,
                  background: b?.gray ? "#333" : "#5271c4",
                }}
                onClick={async () => {
                  await onClick(b?.value);
                  if (b?.closeModal) onClose();
                }}
              >
                {b?.text}
              </BtnPrimary>
            ))}
          </Footer>
        </Box>
      </Container>
    )
  );
}

Alert.defaultProps = {
  type: "none",
  title: "",
  text: "",
  open: false,
  buttons: [
    {
      text: "Ok",
      value: true,
      closeModal: true,
      gray: false,
    },
  ],
  onClose: () => {},
  onClick: () => {},
};

Alert.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  buttons: PropTypes.array,
  type: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
};

export default Alert;
