import styled from "styled-components";
import logo from "~/assets/img/logo-exploreit-branco.png";
import bg from "~/assets/img/fundo-auth.png";

export const BgAuth = styled.div`
  width: 60%;
  height: 100vh;
  padding: 60px;
  background: ${(e) => e.theme?.primaryColor};
  position: relative;
`;

export const BgFormAuth = styled.div`
  padding: 200px;
  width: 60%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: flex-start;
  background: #eaeaea url(${bg}) center bottom / cover no-repeat;
`;

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Title = styled.h1`
  font-size: 39px;
  font-weight: ${(props) => (props.noBold ? "normal" : "bold")};
  color: ${(props) =>
    props.colorPrimary ? "#fff" : `${(e) => e.theme?.secondaryColor}`};
  margin-bottom: ${(props) => (props.lowMargin ? "8px" : "30px")};
`;

export const Label = styled.label`
  font-size: 16px;
  color: ${(props) =>
    props.colorPrimary ? "#fff" : `${(e) => e.theme?.secondaryColor}`};
  font-weight: ${(props) => (props.noBold ? "normal" : "bold")};
  margin-bottom: ${(props) => props.margin && "30px"};
`;

export const BgLogoAuth = styled.div`
  background: url(${(props) => (props.logo ? props.logo.path : logo)}) center
    bottom / cover no-repeat;
  bottom: 0px;
  height: 49px;
  position: absolute;
  width: 195px;
  padding: 0;
  margin: 30px;
  right: 10px;
`;

export const BtnAuth = styled.a`
  color: ${(e) => e.theme?.primaryColor};
  font-weight: bold;
  transition: all 0.4s ease 0s;
  cursor: pointer;
  margin-top: 20px;
  transition: all 0.4s;
  :hover {
    opacity: 0.7;
  }
`;
