/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  FaPlus,
  FaEllipsisH,
  FiEdit3,
  FiEye,
  FiTrash2,
  FiX,
  FiGlobe,
  FaFacebook,
  GrInstagram,
} from "react-icons/all";

import {
  getHotels,
  saveNewHotel,
  updateHotel,
  deleteHotel,
  searchHotel,
} from "~/store/modules/hotels/actions";

import Container from "~/components/Container";
import { ChannelIcon, FormContainer } from "./styles";
import {
  Col5,
  Col6,
  Col12,
  Box,
  BoxContent,
  BoxTitle,
  Icon,
  BtnPrimaryCircle,
  Title,
  Table,
  TBody,
  Th,
  Tr,
  Td,
  Row,
  MenuActions,
  BoxFooter,
  SideContent,
  SideContentContainer,
  CloseBtn,
  SideContentHeader,
  ListTitle,
  ListItem,
  Strong,
  SideContentMenu,
  BtnCircle,
  NoData,
  Gallery,
  RateIcon,
  RateContainer,
  Label,
} from "~/styles/elements";

import SearchBar from "~/components/SearchBar";
import Modal from "~/components/Modal";
import Input from "~/components/Input";
import Alert from "~/components/Alert";
import Pagination from "~/components/Pagination";
import Loader from "~/components/Loader";
import AddRoom from "./components/AddRoom";
import UploadImage from "~/components/UploadImage";
import Rate from "~/components/Rate";
import Carousel from "~/components/Carousel";
import CheckBox from "~/components/CheckBox";

import { fullDate } from "~/libraries/utils";

import language from "./language.json";

function Hotels() {
  const dispatch = useDispatch();

  const { hotels, pages, loading } = useSelector((state) => state.hotels);

  const { lang } = useSelector((state) => state.profile.profile);

  const [page, setPage] = useState(1);

  const [query, setQuery] = useState("");
  const search = (queryterm, querypage = 1) => {
    dispatch(searchHotel(queryterm, querypage));
  };
  useEffect(() => {
    if (!query) {
      dispatch(getHotels(page));
    } else {
      search(query, page);
    }
  }, [page]);

  const [hotelName, setHotelName] = useState("");
  const [nameValidation, setNameValidation] = useState(true);

  const [about, setAbout] = useState("");
  const [aboutValidation, setAboutValidation] = useState(true);

  const [phone, setPhone] = useState("");
  const [phoneValidation, setPhoneValidation] = useState(true);

  const [cityName, setCityName] = useState("");
  const [cityValidation, setCityValidation] = useState(true);

  const [address, setAddress] = useState("");
  const [addressValidation, setAddressValidation] = useState(true);

  const [site, setSite] = useState("");

  const [facebook, setFacebook] = useState("");

  const [instagram, setInstagram] = useState("");

  const [rate, setRate] = useState(0);
  const [rateValidation, setRateValidation] = useState(true);

  const [hotel, setHotel] = useState(null);
  const [showHotel, setShowHotel] = useState(false);
  const [showSideModal, setShowSideModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showMore, setShowMore] = useState(null);

  useEffect(() => {
    if (showHotel || showDelete || showSideModal) {
      setShowMore(false);
    }

    if (showHotel) {
      setShowSideModal(false);
    }
  }, [showHotel, showDelete, showSideModal]);

  const More = (item) => {
    if (showMore === item) {
      setShowMore(null);
    } else {
      setShowMore(item);
    }
  };

  const [rooms, setRooms] = useState([]);
  const [roomsValidation, setRoomsValidation] = useState(true);

  const Validate = () => {
    let validated = true;

    if (!hotelName) {
      setNameValidation(false);
      validated = false;
    }

    if (!about) {
      setAboutValidation(false);
      validated = false;
    }

    if (!phone) {
      setPhoneValidation(false);
      validated = false;
    }

    if (!cityName) {
      setCityValidation(false);
      validated = false;
    }

    if (!address) {
      setAddressValidation(false);
      validated = false;
    }

    if (!rate) {
      setRateValidation(false);
      validated = false;
    }

    if (rooms?.length === 0) {
      setRoomsValidation(false);
      validated = false;
    }

    return validated;
  };

  const AddClick = ({ room, capacity }) => {
    setRooms([...rooms, { room, capacity }]);
  };

  const RemoveClick = (index) => {
    const removeInputs = [...rooms];
    removeInputs.splice(index, 1);
    setRooms(removeInputs);
  };

  const EditClick = (data, index) => {
    const newInput = [...rooms];
    newInput[index] = { ...data };
    setRooms(newInput);
  };

  const [breakfast, setBreakfast] = useState(false);
  const [lunch, setLunch] = useState(false);
  const [dinner, setDinner] = useState(false);

  useEffect(() => {
    if (!showHotel && !showSideModal && !showDelete) {
      setHotel(null);
      setHotelName("");
      setAbout("");
      setPhone("");
      setCityName("");
      setAddress("");
      setSite("");
      setFacebook("");
      setInstagram("");
      setRate(0);
      setBreakfast(false);
      setLunch(false);
      setDinner(false);
      setRooms([]);
      setNameValidation(true);
      setRoomsValidation(true);
      setAboutValidation(true);
      setPhoneValidation(true);
      setCityValidation(true);
      setAddressValidation(true);
      setRateValidation(true);
      setRoomsValidation(true);
    }
  }, [showHotel, showSideModal, showDelete]);
  const View = (i) => {
    setHotel(hotels[i]);
    setShowSideModal(true);
  };

  useEffect(() => {
    if (hotel) {
      setHotelName(hotel?.name);
      setAbout(hotel?.about);
      setPhone(hotel?.phone);
      setCityName(hotel?.city);
      setAddress(hotel?.address);
      setSite(hotel?.channels?.site);
      setFacebook(hotel?.channels?.facebook);
      setInstagram(hotel?.channels?.instagram);
    }
  }, [hotel]);

  const SaveOrUpdate = (id) => {
    setNameValidation(true);
    setAboutValidation(true);
    setPhoneValidation(true);
    setCityValidation(true);
    setAddressValidation(true);
    setRateValidation(true);
    setRoomsValidation(true);

    const validated = Validate();

    if (validated) {
      dispatch(!hotel ? saveNewHotel(hotelName) : updateHotel(id, hotelName));
      setShowHotel(false);
    }
  };

  const Edit = (i) => {
    setHotel(hotels[i]);
    setShowHotel(true);
  };

  const PreDelete = (i) => {
    setHotel(hotels[i]);
    setShowMore(null);
    setShowSideModal(false);
    setShowDelete(true);
  };

  const Del = (confirm) => {
    if (confirm) dispatch(deleteHotel(hotel?.id));
  };

  const [images, setImages] = useState([]);

  const RemoveImage = (index) => {
    const removeImages = [...images];
    removeImages.splice(index, 1);
    setImages(removeImages);
  };

  const AddImage = (img) => {
    if (img) {
      setImages([...images, img]);
    }
  };

  return (
    <Container>
      <Row>
        <Col12>
          <Box>
            <BoxTitle>
              <Title>{language[lang].title.hotels}</Title>
              <Col5 isInput>
                <SearchBar
                  placeholder={language[lang].input.search}
                  onSearch={search}
                  onClear={() => {
                    setQuery("");
                    dispatch(getHotels(1));
                  }}
                  onChange={setQuery}
                />
                <BtnPrimaryCircle onClick={() => setShowHotel(true)}>
                  <FaPlus />
                </BtnPrimaryCircle>
              </Col5>
            </BoxTitle>
            <BoxContent>
              <Table>
                <Tr>
                  <Th>{language[lang].table.name}</Th>
                  <Th>{language[lang].table.city}</Th>
                  <Th>{language[lang].table.phone}</Th>
                  <Th>{language[lang].table.rating}</Th>
                  <Th>{language[lang].table.channels}</Th>
                  <Th>{language[lang].table.createdDate}</Th>
                  <Th />
                </Tr>
                <TBody>
                  {hotels?.length > 0 ? (
                    hotels?.map((h, i) => (
                      <Tr key={`hotels-${h?.id}`}>
                        <Td>{h?.name}</Td>
                        <Td>{h?.city}</Td>
                        <Td>{h?.phone}</Td>
                        <Td>
                          <RateIcon />
                          <RateIcon />
                          <RateIcon />
                          <RateIcon />
                          <RateIcon />
                        </Td>
                        <Td>
                          <ChannelIcon href={h?.channels?.site} target="_blank">
                            <FiGlobe />
                          </ChannelIcon>
                          <ChannelIcon
                            href={h?.channels?.facebook}
                            target="_blank"
                          >
                            <FaFacebook />
                          </ChannelIcon>
                          <ChannelIcon
                            href={h?.channels?.instagram}
                            target="_blank"
                          >
                            <GrInstagram />
                          </ChannelIcon>
                        </Td>
                        <Td>{fullDate(h?.createdDate, lang)}</Td>
                        <Td className="actions">
                          <BtnPrimaryCircle isGrey onClick={() => More(i)}>
                            <FaEllipsisH />
                          </BtnPrimaryCircle>
                          {showMore === i && (
                            <MenuActions>
                              <Icon onClick={() => Edit(i)}>
                                <FiEdit3 />
                              </Icon>
                              <Icon onClick={() => View(i)}>
                                <FiEye />
                              </Icon>
                              <Icon
                                className="last"
                                onClick={() => PreDelete(i)}
                              >
                                <FiTrash2 />
                              </Icon>
                            </MenuActions>
                          )}
                        </Td>
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan="7">
                        {loading ? (
                          <NoData>{language[lang].loading}</NoData>
                        ) : (
                          <NoData>{language[lang].noData}</NoData>
                        )}
                      </Td>
                    </Tr>
                  )}
                </TBody>
              </Table>
            </BoxContent>
            <BoxFooter right noBorder>
              <Pagination page={page} pages={pages} onPaginate={setPage} />
            </BoxFooter>
          </Box>
        </Col12>
      </Row>
      <Modal
        open={showHotel}
        modalTitle={
          !hotel
            ? language[lang].title.newHotel
            : language[lang].title.editHotel
        }
        onCancel={() => setShowHotel(false)}
        cancelText={language[lang].button.cancel}
        confirmText={language[lang].button.save}
        onConfirm={() => SaveOrUpdate(hotel?.id)}
      >
        <Col5 style={{ paddingRight: 150 }}>
          <Input
            placeholder={language[lang].input.placeholder.name}
            label={language[lang].input.label.name}
            validationText={language[lang].validation.name}
            validation={nameValidation}
            value={hotelName}
            onChange={(e) => setHotelName(e.target.value)}
          />
          <Input
            placeholder={language[lang].input.placeholder.about}
            label={language[lang].input.label.about}
            validation={aboutValidation}
            validationText={language[lang].validation.about}
            value={about}
            onChange={(e) => setAbout(e.target.value)}
          />
          <Input
            mask={lang === "br" ? "(99) 9999-9999" : "(999) 999-9999"}
            placeholder={language[lang].input.placeholder.phone}
            label={language[lang].input.label.phone}
            validation={phoneValidation}
            validationText={language[lang].validation.phone}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <Input
            placeholder={language[lang].input.placeholder.city}
            label={language[lang].input.label.city}
            validation={cityValidation}
            validationText={language[lang].validation.city}
            value={cityName}
            onChange={(e) => setCityName(e.target.value)}
          />
          <Input
            placeholder={language[lang].input.placeholder.address}
            label={language[lang].input.label.address}
            validation={addressValidation}
            validationText={language[lang].validation.address}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <Input
            placeholder={language[lang].input.placeholder.site}
            label={language[lang].input.label.site}
            validation
            value={site}
            onChange={(e) => setSite(e.target.value)}
          />
          <Input
            placeholder={language[lang].input.placeholder.facebook}
            label="Facebook"
            validation
            value={facebook}
            onChange={(e) => setFacebook(e.target.value)}
          />
          <Input
            placeholder={language[lang].input.placeholder.instagram}
            label="Instagram"
            validation
            value={instagram}
            onChange={(e) => setInstagram(e.target.value)}
          />
          <Rate
            validation={rateValidation}
            validationText={language[lang].validation.rate}
            onRate={() => setRate()}
            label={language[lang].input.label.rate}
          />
          <CheckBox
            checked={breakfast}
            label={language[lang].input.label.breakfast}
            onClick={() => setBreakfast(!breakfast)}
          />
          <CheckBox
            checked={lunch}
            label={language[lang].input.label.lunch}
            onClick={() => setLunch(!lunch)}
          />
          <CheckBox
            checked={dinner}
            label={language[lang].input.label.dinner}
            onClick={() => setDinner(!dinner)}
          />
        </Col5>
        <Col6>
          <Row style={{ marginBottom: 30 }}>
            <Gallery>
              {images?.map((img, i) => (
                <UploadImage onDelete={() => RemoveImage(i)} image={img} />
              ))}
              <UploadImage onAdd={(image) => AddImage(image)} />
            </Gallery>
          </Row>
          <Row>
            <Label
              style={{
                fontSize: 12,
                color: "#333",
                fontWeight: 600,
                marginBottom: 10,
                top: "-10px",
                position: "relative",
              }}
            >
              {language[lang].input.label.rooms}
            </Label>
            {rooms?.map((d, i) => (
              <AddRoom
                index={i}
                data={d}
                onEdit={(data) => EditClick(data, i)}
                onDelete={() => RemoveClick(i)}
              />
            ))}
            <AddRoom onAdd={AddClick} validation={roomsValidation} />
            <FormContainer />
          </Row>
        </Col6>
      </Modal>
      <Alert
        open={showDelete}
        onClose={() => setShowDelete(false)}
        onClick={(value) => Del(value)}
        title={language[lang].alert.title}
        text={language[lang].alert.delete}
        type="error"
        buttons={[
          {
            text: language[lang].button.delete,
            closeModal: true,
            value: true,
          },
          {
            text: language[lang].button.cancel,
            gray: true,
            closeModal: true,
            value: false,
          },
        ]}
      />
      {showSideModal && (
        <SideContent>
          <CloseBtn onClick={() => setShowSideModal(false)}>
            <FiX />
          </CloseBtn>
          <SideContentHeader>
            <Carousel />
          </SideContentHeader>
          <SideContentContainer>
            <ListItem
              style={{
                display: "flex",
                marginBottom: 20,
                alignItems: "flex-start",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <ListTitle style={{ marginBottom: 0, maxWidth: "60%" }}>
                {hotel?.name}
              </ListTitle>
              <RateContainer>
                <RateIcon />
                <RateIcon />
                <RateIcon />
                <RateIcon />
                <RateIcon />
              </RateContainer>
            </ListItem>
            <ListItem>
              <Strong>{language[lang]?.sideModal?.about}:</Strong>{" "}
              {hotel?.about}
            </ListItem>
            <ListItem>
              <Strong>{language[lang]?.sideModal?.address}:</Strong>{" "}
              {hotel?.address}
            </ListItem>
            <ListItem>
              <Strong>{language[lang]?.sideModal?.city}:</Strong> {hotel?.city}
            </ListItem>
            <ListItem>
              <Strong>{language[lang]?.sideModal?.phone}:</Strong>{" "}
              {hotel?.phone}
            </ListItem>
            {hotel?.channels?.site && (
              <ListItem>
                <Strong>{language[lang]?.sideModal?.site}:</Strong>{" "}
                {hotel?.channels?.site}
              </ListItem>
            )}
            {hotel?.channels?.facebook && (
              <ListItem>
                <Strong>{language[lang]?.sideModal?.facebook}:</Strong>{" "}
                {hotel?.channels?.facebook}
              </ListItem>
            )}
            {hotel?.channels?.instagram && (
              <ListItem>
                <Strong>{language[lang]?.sideModal?.instagram}:</Strong>{" "}
                {hotel?.channels?.instagram}
              </ListItem>
            )}
            <ListItem>
              <Strong>{language[lang]?.sideModal?.createdDate}:</Strong>{" "}
              {fullDate(hotel?.createdDate, lang)}
            </ListItem>
            <ListItem style={{ marginTop: 30 }}>
              <Strong>Suítes</Strong>
            </ListItem>
            {hotel?.rooms?.map((r) => (
              <ListItem>
                {r?.name} - capacidade {r?.capacity} hóspedes
              </ListItem>
            ))}
          </SideContentContainer>
          <SideContentMenu>
            <BtnCircle
              onClick={() => {
                setShowSideModal(false);
                setShowHotel(true);
              }}
            >
              <FiEdit3 />
            </BtnCircle>
            <BtnCircle
              onClick={() => {
                setShowSideModal(false);
                setShowDelete(true);
              }}
            >
              <FiTrash2 />
            </BtnCircle>
          </SideContentMenu>
        </SideContent>
      )}
      <Loader show={loading} />
    </Container>
  );
}

export default Hotels;
