import styled from "styled-components";

export const Container = styled.div`
  width: 60px;
  height: 60px;
  position: relative;
`;

export const Icon = styled.div`
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(e) => e.theme?.primaryColor};
  font-size: 24px;
  cursor: pointer;

  && svg {
    margin-top: 3px;
  }
`;

export const Img = styled.img`
  height: 30px;
  width: 30px;
  border-radius: 50%;
`;

export const ContentContainer = styled.div`
  position: absolute:
  top: 60px;
  left: -50px;
  background: #fff;
  border: 1px solid ${(e) => e.theme?.primaryColor}4d;
  padding: 10px 15px;
  width: 300px;
  min-height: 40px;
  border-radius: 10px;
  margin-top: 5px;
  margin-left: -120px;
`;

export const TasksContainer = styled.div`
  max-height: 350px;
  overflow-y: auto;
`;
export const NewTaskContainer = styled.div`
  max-height: 350px;
  overflow-y: auto;
  width: 100%;
`;

export const Pin = styled.span`
  min-width: 16px;
  height: 16px;
  border-radius: 5px;
  background: ${(e) => e.theme?.primaryColor};
  font-size: 11px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  padding: 5px;
  position: absolute;
  top: 10px;
  right: 5px;
`;

export const TaskItem = styled.div`
  display: flex;
  max-height: 100px;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${(props) =>
    props.last ? "none" : `1px solid ${(e) => e.theme?.primaryColor}4d`};
  position: relative;
`;

export const TaskInfo = styled.div`
  width: 100%;
  padding: 15px 0px;
`;

export const TaskTitle = styled.h6`
  font-size: 11px;
  color: ${(e) => e.theme?.secondaryColor};
  font-weight: bold;
`;

export const TaskContent = styled.p`
  font-size: 10px;
  color: ${(e) => e.theme?.secondaryColor};
  margin-top: 5px;
`;

export const TaskAction = styled.div`
  width: 268px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  z-index: 10;
  background: ${(e) => e.theme?.primaryColor}0d;
  position: absolute;
`;

export const TaskActionIcon = styled.span`
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  background: ${(props) =>
    props.gray
      ? `${(e) => e.theme?.secondaryColor}`
      : `${(e) => e.theme?.primaryColor}`};
  border-radius: 50%;
  margin-right: 5px;

  :hover {
    opacity: 0.8;
  }

  && svg {
    margin-left: 0px;
  }
`;

export const TaskDate = styled.span`
  font-size: 20px;
  text-align: center;
  color: ${(e) => e.theme?.primaryColor};
  font-weight: bold;
`;

export const TaskMonth = styled.span`
  font-size: 12px;
  text-align: center;
  color: ${(e) => e.theme?.secondaryColor};
  font-weight: bolder;
`;

export const NoTasks = styled.p`
  font-size: 10px;
  text-align: center;
  color: ${(e) => e.theme?.secondaryColor}99;
  margin-top: 15px;
`;

export const PlusBtn = styled.span`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(e) => e.theme?.primaryColor};
  color: #fff;
  font-size: 22px;
  cursor: pointer;
  position: absolute;
  top: 57px;
  right: -128px;
  transition: all 0.4s;

  :hover {
    opacity: 0.8;
  }
`;
