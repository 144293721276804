import React from "react";

import PropTypes from "prop-types";
import { Container, Label, Error, DropInput } from "./styles";

function DropDown({
  onChange,
  placeholder,
  validation,
  validationText,
  label,
  data,
  emptyText,
  defaultValue,
}) {
  return (
    <Container>
      {label && <Label validation={validation}>{label}</Label>}
      <DropInput
        data={data}
        onChange={onChange}
        valueField="id"
        textField="name"
        placeholder={placeholder}
        validation={validation}
        messages={{ emptyList: emptyText }}
        defaultValue={defaultValue}
      />
      {!validation && <Error>{validationText}</Error>}
    </Container>
  );
}

DropDown.defaultProps = {
  placeholder: "",
  label: null,
  onChange: () => {},
  validation: true,
  validationText: "",
  emptyText: "",
  data: [],
  defaultValue: null,
};

DropDown.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array,
  placeholder: PropTypes.string,
  emptyText: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  validation: PropTypes.bool,
  validationText: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValue: PropTypes.any,
};

export default DropDown;
