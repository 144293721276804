export function getInfoDashboardRequest() {
  return {
    type: "@dashboard/getInfo_REQUEST",
  };
}

export function getInfoDashboardSuccess(payload) {
  return {
    type: "@dashboard/getInfo_SUCCESS",
    payload,
  };
}

export function dashboardFailure() {
  return {
    type: "@dashboard/ERROR",
  };
}
