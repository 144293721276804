import styled from "styled-components";
import InputMask from "react-input-mask";

export const Container = styled.div`
  width: ${(props) => (props.isBoxed ? "300px" : "100%")};
  padding: ${(props) => (props.isBoxed ? "0px" : "5px")};
  margin-bottom: 10px;
`;

export const InputText = styled.input`
  width: 100%;
  height: 40px;
  border: none;
  border-bottom: ${(props) =>
    !props.validation
      ? "1px solid #da1e1e"
      : `1px solid ${props.theme?.primaryColor}`};
  padding: 5px;
  color: #333;
  margin-bottom: 3px;
`;

export const InputBoxed = styled.input`
  background: rgba(255, 255, 255, 0.7);
  border: ${(props) =>
    !props.validation
      ? "1px solid #da1e1e"
      : `1px solid ${props.theme?.primaryColor}`};
  border-radius: 5px;
  padding: 10px;
  color: #333;
  width: 100%;
  height: 40px !important;
  display: flex;
  margin-top: 10px;
`;

export const InputBoxedMasked = styled(InputMask)`
  width: 100%;
  height: 40px;
  border: ${(props) =>
    !props.validation
      ? "1px solid #da1e1e"
      : `1px solid ${props.theme?.primaryColor}`};
  border-radius: 5px;
  padding: 5px;
  color: #333;
  margin-bottom: 3px;
  margin-top: 10px;
`;

export const Label = styled.label`
  font-size: 12px;
  font-weight: 600;
  color: ${(props) =>
    !props.validation ? "#da1e1e" : `${props.theme?.secondaryColor}`};
`;

export const Error = styled.span`
  color: #da1e1e;
  font-size: 11px;
`;

export const InputMasked = styled(InputMask)`
  width: 100%;
  height: 40px;
  border: none;
  border-bottom: ${(props) =>
    !props.validation
      ? "1px solid #da1e1e"
      : `1px solid ${props.theme?.primaryColor}`};
  padding: 5px;
  color: #333;
  margin-bottom: 3px;
`;
