import styled from "styled-components";

export const Container = styled.div`
  padding: 40px;
  background: #3333334d;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 60px;
  left: 80px;
  bottom: 0;
  right: 0;
  width: auto;
  height: auto;
  z-index: 15;
`;

export const Box = styled.div`
  background: #fff;
  width: 580px;
  border-radius: 7px;
  min-height: 100px;
  box-shadow: 0 0 3px -3px #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 40px 20px;
`;

export const Icon = styled.span`
  font-size: 80px;
  color: #5271c4;
`;

export const Title = styled.h4`
  font-size: 18px;
  color: #333;
  margin-top: 15px;
`;

export const Text = styled.p`
  font-size: 16px;
  color: #333;
  margin: 20px 0px 40px;
  line-height: 24px;
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
  border-top: 1px solid #5271c44d;
`;
