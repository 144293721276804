/* eslint-disable import/no-cycle */
import { call, put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

import api from "../../../services/api";
import history from "../../../services/history";
import { authFailure, signInSuccess } from "./actions";

function* signIn({ payload }) {
  try {
    const { data } = yield call(api.post, "v2/agencies/session", payload);
    if (data.error) throw new Error(data.error);
    yield put(signInSuccess(data));
    history.push("/dashboard");
  } catch (e) {
    toast.error(e.message);
    yield put(authFailure());
  }
}

function signOut() {
  history.push("/");
}

export default all([
  takeLatest("@auth/signIn_REQUEST", signIn),
  takeLatest("@auth/logout", signOut),
]);
