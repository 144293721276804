import produce from "immer";

const INITIAL_STATE = {
  users: [],
  pages: 1,
  total: 0,
  profile: null,
  loading: false,
};

export default (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case "@auth/signIn_SUCCESS": {
        draft.profile = action.payload.user;
        break;
      }
      case "@auth/logout": {
        draft.profile = null;
        break;
      }
      case "@user/get_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@user/get_SUCCESS": {
        draft.loading = false;
        draft.users = action.payload.users;
        break;
      }
      case "@user/delete_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@user/delete_SUCCESS": {
        draft.loading = false;
        draft.users = action.payload.users;
        break;
      }
      case "@user/search_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@user/search_SUCCESS": {
        draft.loading = false;
        draft.users = action.payload.users;
        break;
      }
      case "@user/store_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@user/store_SUCCESS": {
        draft.loading = false;
        draft.users = action.payload.users;
        break;
      }
      case "@user/update_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@user/update_SUCCESS": {
        draft.loading = false;
        draft.users = action.payload.users;
        if (action.payload.user)
          draft.profile = {
            ...draft.profile,
            ...action.payload.user,
            lang: action.payload.user.id_language === 1 ? "br" : "en",
          };
        break;
      }
      case "@user/change_pass_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@user/change_pass_SUCCESS": {
        draft.loading = false;
        break;
      }
      case "@user/lang": {
        draft.profile = {
          ...draft.profile,
          lang: action.payload.lang,
        };
        break;
      }
      case "@user/error": {
        draft.loading = false;
        break;
      }
      default:
        break;
    }
  });
