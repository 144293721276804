import produce from "immer";

const INITIAL_STATE = {
  hotels: [],
  pages: 1,
  total: 0,
};

export default (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case "@hotels/get": {
        draft.loading = true;
        break;
      }
      case "@hotels/store": {
        draft.loading = true;
        break;
      }
      case "@hotels/update": {
        draft.loading = true;
        break;
      }
      case "@hotels/delete": {
        draft.loading = true;
        break;
      }
      case "@hotels/search": {
        draft.loading = true;
        break;
      }
      case "@hotels/SUCCESS": {
        draft.hotels = action.payload.hotels;
        draft.total = action.payload.total;
        draft.pages = action.payload.pages;
        draft.loading = false;
        break;
      }
      case "@hotels/ERROR": {
        draft.loading = false;
        break;
      }
      default:
        break;
    }
  });
