import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
  margin-bottom: 30px;
  margin-top: 15px;
  label {
    cursor: pointer;
    transition: all 0.4s;
    &:hover {
      opacity: 0.7;
    }
    img {
      height: 90px;
      width: 90px;
      border-radius: 50%;
      border: 3px solid 5271c44d;
      background: #eaeaea;
    }
    input {
      display: none;
    }
  }
`;
