/* eslint-disable import/no-cycle */
import { call, put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import { store } from "~/store";

import api from "~/services/api";
import {
  travelsFailure,
  getListSuccess,
  getListRequest,
  saveNewTravelSuccess,
  updateTravelSuccess,
  deleteTravelSuccess,
  searchTravelSuccess,
} from "./actions";

function* get() {
  // function* get({ payload: { page } }) {
  try {
    // const { data } = yield call(api.get, `/v2/agencies/clients/${page}`);
    const data = [
      {
        code: "TR123",
        name: "Festival de Praias Brasileiras",
        destinies: "Rio de Janeiro + 5",
        qtdPassengers: 2,
        startDate: "14/02/2021",
        endDate: "21/02/2021",
      },
      {
        code: "TR123",
        name: "Festival de Praias Brasileiras",
        destinies: "Rio de Janeiro + 5",
        qtdPassengers: 2,
        startDate: "14/02/2021",
        endDate: "21/02/2021",
      },
      {
        code: "TR123",
        name: "Festival de Praias Brasileiras",
        destinies: "Rio de Janeiro + 5",
        qtdPassengers: 2,
        startDate: "14/02/2021",
        endDate: "21/02/2021",
      },
    ];
    if (data.error) throw new Error(data.error);

    yield put(getListSuccess(data));
  } catch (e) {
    toast.error(e.message);
    yield put(travelsFailure());
  }
}

function* save({ payload }) {
  try {
    const { data } = yield call(api.post, "/v2/agencies/clients", payload);
    if (data.error) throw new Error(data.error);

    yield put(getListRequest());
    yield put(saveNewTravelSuccess(data));

    const { lang } = store.getState().profile.profile;
    toast.success(
      lang === "en"
        ? "Travel successfully created!"
        : "Viagem criada com sucesso!"
    );
  } catch (e) {
    toast.error(e.message);
    yield put(travelsFailure());
  }
}

function* update({ payload }) {
  try {
    const { data } = yield call(api.put, "/v2/agencies/clients", payload);
    if (data.error) throw new Error(data.error);

    yield put(getListRequest());
    yield put(updateTravelSuccess(data));

    const { lang } = store.getState().profile.profile;
    toast.success(
      lang === "en"
        ? "Travel successfully updated!"
        : "Viagem atualizada com sucesso!"
    );
  } catch (e) {
    toast.error(e.message);
    yield put(travelsFailure());
  }
}

function* del({ payload: { id } }) {
  try {
    const { data } = yield call(api.delete, `/v2/agencies/clients/${id}`);
    if (data.error) throw new Error(data.error);

    yield put(getListSuccess());
    yield put(deleteTravelSuccess(data));

    const { lang } = store.getState().profile.profile;
    toast.success(
      lang === "en"
        ? "Travel successfully deleted!"
        : "Viagem excluída com sucesso!"
    );
  } catch (e) {
    toast.error(e.message);
    yield put(travelsFailure());
  }
}

function* search({ payload: { queryterm, querypage } }) {
  try {
    const { data } = yield call(
      api.get,
      `/v2/agencies/clients/search/${queryterm}/${querypage}`
    );
    if (data.error) throw new Error(data.error);

    yield put(searchTravelSuccess(data));
  } catch (e) {
    toast.error(e.message);
    yield put(travelsFailure());
  }
}

export default all([
  takeLatest("@travels/get_list_REQUEST", get),
  takeLatest("@travels/save_travel_REQUEST", save),
  takeLatest("@travels/update_travel_REQUEST", update),
  takeLatest("@travels/delete_travel_REQUEST", del),
  takeLatest("@travels/search_travel_REQUEST", search),
]);
