/* eslint-disable import/no-cycle */
import { call, put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

import api from "~/services/api";
import { dashboardFailure, getInfoDashboardSuccess } from "./actions";

function* getInfoDashboard({ payload }) {
  try {
    const { data } = yield call(api.get, "v2/agencies/dashboard", payload);
    if (data.error) throw new Error(data.error);

    yield put(getInfoDashboardSuccess(data));
  } catch (e) {
    toast.error(e.message);
    yield put(dashboardFailure());
  }
}

export default all([
  takeLatest("@dashboard/getInfo_REQUEST", getInfoDashboard),
]);
