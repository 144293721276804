/* eslint-disable import/no-cycle */
import { all } from "redux-saga/effects";

import auth from "./auth/sagas";
import dashboard from "./dashboard/sagas";
import users from "./users/sagas";
import profile from "./profile/sagas";
import clients from "./clients/sagas";
import travels from "./travels/sagas";
import toast from "./toast/sagas";
import tasks from "./tasks/sagas";
import hotels from "./hotels/sagas";

export default function* rootSaga() {
  return yield all([
    auth,
    dashboard,
    users,
    profile,
    clients,
    toast,
    travels,
    tasks,
    hotels,
  ]);
}
