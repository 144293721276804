import produce from "immer";

const INITIAL_STATE = {
  clients: [],
  pages: 1,
  total: 0,
  loading: false,
};

export default (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case "@clients/get_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@clients/SUCCESS": {
        draft.clients = action.payload.clients;
        draft.total = action.payload.total;
        draft.pages = action.payload.pages;
        draft.loading = false;
        break;
      }
      case "@clients/store_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@clients/update_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@clients/delete_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@clients/search_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@clients/ERROR": {
        draft.loading = false;
        break;
      }
      default:
        break;
    }
  });
