import styled from "styled-components";

export const Container = styled.div`
  width: 400px;
  height: 45px;
  display: flex;
  margin-right: 60px;
  position: relative;
`;

export const Input = styled.input`
  background: ${(e) => e.theme?.primaryColor}0d;
  border: none;
  border-radius: 20px;
  padding: 23px 45px 23px 23px;
  color: #333;
  width: 100%;
  height: 45px;
`;

export const Icon = styled.span`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(e) => e.theme?.primaryColor};
  font-size: 24px;
  position: absolute;
  cursor: pointer;
  transition: all 0.4s;
  right: 0;
  :hover {
    background: #3333330d;
  }
  && svg {
    margin-top: 3px;
  }
`;
