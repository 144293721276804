import { format, formatDistanceToNow } from "date-fns";
import { enUS, ptBR } from "date-fns/locale";

export function isEmail(mail) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(mail).toLowerCase());
}

export function clearPhone(phone) {
  return phone.replace(/\D/g, "");
}

function adjustDate(date) {
  return new Date(
    new Date(date).valueOf() + new Date(date).getTimezoneOffset() * 60 * 1000
  );
}

export function fullDate(date, lang) {
  if (lang === "br") {
    return format(new Date(date), "dd/MM/yyyy' às 'HH:mm");
  }
  return format(new Date(date), "MM/dd/yyyy' at 'HH:mm");
}

export function getDate(date, lang) {
  if (lang === "br") {
    return format(new Date(adjustDate(date)), "dd/MM/yyyy");
  }
  return format(new Date(adjustDate(date)), "MM/dd/yyyy");
}

export function Day(date) {
  return format(new Date(adjustDate(date)), "dd");
}

export function Month(date, lang) {
  return format(new Date(adjustDate(date)), "LLL", {
    locale: lang === "br" ? ptBR : enUS,
  });
}

export function getAge(date, lang) {
  return formatDistanceToNow(new Date(adjustDate(date)), {
    locale: lang === "br" ? ptBR : enUS,
  })
    .replace("cerca de ", "")
    .replace("about ", "");
}
