/* eslint-disable import/no-cycle */
import { call, put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import { store } from "~/store";

import api from "~/services/api";
import { clientsFailure, clientSuccess } from "./actions";

function* get({ payload: { page } }) {
  try {
    const { data } = yield call(api.get, `/v2/agencies/clients/${page}`);
    if (data.error) throw new Error(data.error);

    yield put(clientSuccess(data));
  } catch (e) {
    toast.error(e.message);
    yield put(clientsFailure());
  }
}

function* save({ payload }) {
  try {
    const { data } = yield call(api.post, "/v2/agencies/clients", payload);
    if (data.error) throw new Error(data.error);

    yield put(clientSuccess(data));

    const { lang } = store.getState().profile.profile;
    toast.success(
      lang === "en"
        ? "Client successfully created!"
        : "Cliente criado com sucesso!"
    );
  } catch (e) {
    toast.error(e.message);
    yield put(clientsFailure());
  }
}

function* update({ payload }) {
  try {
    const { data } = yield call(api.put, "/v2/agencies/clients", payload);
    if (data.error) throw new Error(data.error);

    yield put(clientSuccess(data));

    const { lang } = store.getState().profile.profile;
    toast.success(
      lang === "en"
        ? "Client successfully updated!"
        : "Cliente atualizado com sucesso!"
    );
  } catch (e) {
    toast.error(e.message);
    yield put(clientsFailure());
  }
}

function* del({ payload: { id } }) {
  try {
    const { data } = yield call(api.delete, `/v2/agencies/clients/${id}`);
    if (data.error) throw new Error(data.error);

    yield put(clientSuccess(data));

    const { lang } = store.getState().profile.profile;
    toast.success(
      lang === "en"
        ? "Client successfully deleted!"
        : "Cliente excluído com sucesso!"
    );
  } catch (e) {
    toast.error(e.message);
    yield put(clientsFailure());
  }
}

function* search({ payload: { queryterm, querypage } }) {
  try {
    const { data } = yield call(
      api.get,
      `/v2/agencies/clients/search/${queryterm}/${querypage}`
    );
    if (data.error) throw new Error(data.error);

    yield put(clientSuccess(data));
  } catch (e) {
    toast.error(e.message);
    yield put(clientsFailure());
  }
}

export default all([
  takeLatest("@clients/get_REQUEST", get),
  takeLatest("@clients/store_REQUEST", save),
  takeLatest("@clients/update_REQUEST", update),
  takeLatest("@clients/delete_REQUEST", del),
  takeLatest("@clients/search_REQUEST", search),
]);
