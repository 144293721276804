import axios from "axios";
// eslint-disable-next-line import/no-cycle
import { store } from "~/store";
import {
  toastManyRequests,
  toastUnauthorized,
} from "~/store/modules/toast/actions";

import getUrl from "~/config/api";

const baseURL = getUrl();

const api = axios.create({ baseURL });

api.interceptors.request.use((config) => {
  const { token } = store.getState().auth;
  if (token) config.headers.Authorization = token;
  return config;
});

api.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error?.response?.status === 401) {
      store.dispatch(toastUnauthorized());
    }
    if (error?.response?.status === 429) {
      store.dispatch(toastManyRequests());
    }
    return Promise.reject(error.response);
  }
);

export default api;
