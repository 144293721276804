import styled from "styled-components";

import { FiSquare, FiCheckSquare } from "react-icons/fi";

export const Container = styled.div`
  align-items: center;
  display: flex;
  margin-top: 30px;
`;

export const CheckIcon = styled(FiCheckSquare)`
  font-size: 24px;
  color: #5271c4;
  margin-right: 5px;
  cursor: pointer;
`;

export const UnCheckIcon = styled(FiSquare)`
  font-size: 24px;
  color: #333;
  margin-right: 5px;
  cursor: pointer;
`;
