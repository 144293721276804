/* eslint-disable import/no-cycle */
import { put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

import { store } from "~/store";
import { logout } from "~/store/modules/auth/actions";

function* unauthorized() {
  try {
    const { lang } = store.getState().profile.profile;

    toast.error(
      lang === "br"
        ? "Sessão expirada ou não autorizada"
        : "Expired or unauthorized session"
    );

    yield put(logout());
  } catch (e) {
    console.log(e.message);
  }
}

function manyRequests() {
  try {
    toast.error("Sessão bloqueada temporariamente");
  } catch (e) {
    console.log(e.message);
  }
}

export default all([
  takeLatest("@toast/unauthorized", unauthorized),
  takeLatest("@toast/many_requests", manyRequests),
]);
