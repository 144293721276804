/* eslint-disable import/no-cycle */
import { call, put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

import api from "~/services/api";
import {
  taskError,
  // getTasksSuccess,
  storeTaskSuccess,
  updateTaskSuccess,
  deleteTaskSuccess,
  endTaskSuccess,
} from "./actions";

import { store as state } from "~/store";

// function* get() {
//   try {
//     const { data } = yield call(api.get, "v2/agencies/tasks");
//     if (data.error) throw new Error(data.error);

//     yield put(getTasksSuccess(data));
//   } catch (e) {
//     toast.error(e.message);
//     yield put(taskError());
//   }
// }

function* store({ payload }) {
  try {
    const { lang } = state.getState().profile.profile;

    const { data } = yield call(api.post, "v2/agencies/tasks", payload);
    if (data.error) throw new Error(data.error);

    yield put(storeTaskSuccess(data));

    toast.success(
      lang === "br" ? "Tarefa criada com sucesso!" : "Task successfully created"
    );
  } catch (e) {
    toast.error(e.message);
    yield put(taskError());
  }
}

function* update({ payload }) {
  try {
    const { lang } = state.getState().profile.profile;

    const { data } = yield call(api.put, "v2/agencies/tasks", payload);
    if (data.error) throw new Error(data.error);

    yield put(updateTaskSuccess(data));

    toast.success(
      lang === "br"
        ? "Tarefa atualizada com sucesso!"
        : "Task updated successfully"
    );
  } catch (e) {
    toast.error(e.message);
    yield put(taskError());
  }
}

function* del({ payload }) {
  try {
    const { lang } = state.getState().profile.profile;

    const { data } = yield call(api.delete, `v2/agencies/tasks/${payload.id}`);
    if (data.error) throw new Error(data.error);

    yield put(deleteTaskSuccess(data));

    toast.success(
      lang === "br"
        ? "Tarefa deletada com sucesso!"
        : "Task successfully deleted"
    );
  } catch (e) {
    toast.error(e.message);
    yield put(taskError());
  }
}

function* end({ payload }) {
  try {
    const { lang } = state.getState().profile.profile;

    const { data } = yield call(api.put, "v2/agencies/tasks/complete", payload);
    if (data.error) throw new Error(data.error);

    yield put(endTaskSuccess(data));

    toast.success(
      lang === "br"
        ? "Tarefa concluida com sucesso!"
        : "Task completed successfully"
    );
  } catch (e) {
    toast.error(e.message);
    yield put(taskError());
  }
}

export default all([
  // takeLatest("@tasks/get_REQUEST", get),
  takeLatest("@tasks/store_REQUEST", store),
  takeLatest("@tasks/update_REQUEST", update),
  takeLatest("@tasks/delete_REQUEST", del),
  takeLatest("@tasks/end_REQUEST", end),
]);
