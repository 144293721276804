/* eslint-disable import/no-cycle */
import { call, put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

import api from "../../../services/api";
import {
  userError,
  updateUserSuccess,
  changePasswordSuccess,
  getAgencySuccess,
} from "./actions";

import { store as state } from "~/store";

function* update({ payload }) {
  try {
    const { data } = yield call(api.put, "v2/agencies/profile", payload);
    if (data.error) throw new Error(data.error);

    const { lang } = state.getState().profile.profile;

    yield put(updateUserSuccess(data));
    toast.success(
      lang === "en"
        ? "Profile updated successfully "
        : "Perfíl alterado com sucesso!"
    );
  } catch (e) {
    toast.error(e.message);
    yield put(userError());
  }
}

function* changePassword({ payload }) {
  try {
    const { data } = yield call(api.put, "v2/agencies/profile/pass", payload);
    if (data.error) throw new Error(data.error);

    const { lang } = state.getState().profile.profile;

    yield put(changePasswordSuccess());
    toast.success(
      lang === "en"
        ? "Password updated successfully"
        : "Senha alterada com sucesso!"
    );
  } catch (e) {
    toast.error(e.message);
    yield put(userError());
  }
}

function* theme({ payload }) {
  try {
    const { data } = yield call(api.put, "v2/agencies/theme", payload);
    if (data.error) throw new Error(data.error);

    yield put(getAgencySuccess(payload));
    toast.success("Theme updated successfully");
  } catch (e) {
    toast.error(e.message);
    yield put(userError());
  }
}

function* agency({ payload }) {
  try {
    const { data } = yield call(api.put, "v2/agencies", payload);
    if (data.error) throw new Error(data.error);

    yield put(getAgencySuccess());
    toast.success("Agency updated successfully");
  } catch (e) {
    toast.error(e.message);
    yield put(userError());
  }
}

export default all([
  takeLatest("@profile/update_REQUEST", update),
  takeLatest("@profile/change_pass_REQUEST", changePassword),
  takeLatest("@agency/theme_REQUEST", theme),
  takeLatest("@agency/update_REQUEST", agency),
]);
