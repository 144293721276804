import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BtnIcon = styled.button`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #fff;
  background: ${(e) => e.theme?.primaryColor};
  border: none;
  border-radius: 7px;
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
`;

export const PageInfo = styled.span`
  font-size: 18px;
  color: ${(e) => e.theme?.primaryColor};
  font-weight: bold;
  font-family: "Lato", sans-serif;
  margin: 0px 15px;
`;

export const Page = styled.span`
  font-size: 14px;
`;
