/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { FiPlusCircle, FiTrash2 } from "react-icons/fi";

import api from "~/services/api";

import { Container } from "./styles";

import Loader from "~/components/Loader";

export default function UploadImage({ onAdd, onDelete, image }) {
  const [imgLoading, setImgLoading] = useState(false);
  const handleChange = async (e) => {
    setImgLoading(true);
    const data = new FormData();
    data.append("file", e.target.files[0]);

    const { data: response } = await api.post("v2/agencies/upload", data);

    // setAvatar(id);
    onAdd(response?.file);
    setImgLoading(false);
  };

  return (
    <Container>
      <label htmlFor={!image ? "avatar" : ""}>
        {image && (
          <span onClick={onDelete}>
            <FiTrash2 />
          </span>
        )}
        {image ? <img src={image?.path} alt={image?.name} /> : <FiPlusCircle />}

        {!image && (
          <input
            type="file"
            id="avatar"
            accept="image/*"
            onChange={(e) => handleChange(e)}
          />
        )}
      </label>
      <Loader show={imgLoading} />
    </Container>
  );
}

UploadImage.defaultProps = {
  onAdd: () => {},
  onDelete: () => {},
  image: null,
};

UploadImage.propTypes = {
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  image: PropTypes.object,
};
