import produce from "immer";

const INITIAL_STATE = {
  token: null,
  authenticated: false,
  loading: false,
};

export default (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case "@auth/signIn_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@auth/signIn_SUCCESS": {
        draft.token = action.payload.token;
        draft.authenticated = true;
        draft.loading = false;
        break;
      }
      case "@auth/error": {
        draft.loading = false;
        break;
      }
      case "@auth/logout": {
        draft.token = null;
        draft.authenticated = false;
        break;
      }
      default:
        break;
    }
  });
