import React, { useState } from "react";

import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import HotelImg from "~/assets/img/hotel.jpeg";
import Hotel from "~/assets/img/four-seasons.jpeg";

import { Container, Image, Slide, Prev, Next } from "./styles";

function Carousel() {
  const images = [
    {
      id: 1,
      path: HotelImg,
    },
    {
      id: 2,
      path: Hotel,
    },
    {
      id: 3,
      path: HotelImg,
    },
  ];

  const [img, setImg] = useState(0);

  const handlePrev = () => {
    if (img === 0) {
      setImg(images?.length - 1);
    } else {
      setImg(img - 1);
    }
  };

  const handleNext = () => {
    if (img === images?.length - 1) {
      setImg(0);
    } else {
      setImg(img + 1);
    }
  };

  return (
    <Container>
      <Slide>
        <Prev onClick={() => handlePrev()}>
          <FiChevronLeft />
        </Prev>
        <Image src={images[img].path} />
        <Next onClick={() => handleNext()}>
          <FiChevronRight />
        </Next>
      </Slide>
    </Container>
  );
}

export default Carousel;
