import React from "react";

import PropTypes from "prop-types";
import {
  Container,
  InputText,
  InputBoxed,
  Label,
  Error,
  InputMasked,
  InputBoxedMasked,
} from "./styles";

function Input({
  onChange,
  value,
  placeholder,
  ref,
  validation,
  validationText,
  label,
  mask,
  isBoxed,
  type,
  style,
}) {
  return (
    <Container isBoxed={isBoxed} style={{ ...style }}>
      {label && <Label validation={validation}>{label}</Label>}
      {mask && !isBoxed && (
        <InputMasked
          mask={mask}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          ref={ref}
          validation={validation}
        />
      )}
      {mask && isBoxed && (
        <InputBoxedMasked
          mask={mask}
          style={style}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          ref={ref}
          validation={validation}
          type={type}
        />
      )}
      {!mask && !isBoxed && (
        <InputText
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          ref={ref}
          validation={validation}
          type={type}
        />
      )}
      {!mask && isBoxed && (
        <InputBoxed
          style={style}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          ref={ref}
          validation={validation}
          type={type}
        />
      )}
      {!validation && <Error>{validationText}</Error>}
    </Container>
  );
}

Input.defaultProps = {
  placeholder: "",
  value: "",
  label: null,
  onChange: () => {},
  ref: null,
  validation: true,
  validationText: "",
  mask: "",
  type: "text",
  isBoxed: false,
  style: {},
};

Input.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  ref: PropTypes.string,
  validation: PropTypes.bool,
  isBoxed: PropTypes.bool,
  validationText: PropTypes.string,
  mask: PropTypes.string,
  type: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

export default Input;
