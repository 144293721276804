import React from "react";
import PropTypes from "prop-types";
import DefaultIcon from "~/assets/img/exploreit-icon-branco.png";

import { Container } from "./styles";

export default function Icon({ onChange, preview }) {
  return (
    <Container>
      <label htmlFor="avatar">
        <img src={preview || DefaultIcon} alt="" />

        <input type="file" id="avatar" accept="image/*" onChange={onChange} />
      </label>
    </Container>
  );
}

Icon.defaultProps = {
  preview: "",
};

Icon.propTypes = {
  onChange: PropTypes.func.isRequired,
  preview: PropTypes.string,
};
