import styled from "styled-components";

export const Container = styled.div`
  width: 100px;
  height: 100px;
  background: #eaeaea4d;
  border: 1px dashed ${(e) => e?.theme?.primaryColor}4d;
  display: flex;
  border-radius: 7px;
  justify-content: center;
  align-self: center;
  margin-bottom: 30px;
  margin-top: 15px;
  label {
    cursor: pointer;
    transition: all 0.4s;
    width: 100px;
    display: flex;
    justify-content: center;
    &:hover {
      opacity: 0.7;
    }
    img {
      height: auto;
      max-height: 98%;
      max-width: 98%;
      width: auto;
      border-radius: 7px;
      border: 3px solid 5271c44d;
      margin: 0px auto;
    }
    input {
      display: none;
    }
  }
`;
