import React, { useState, useEffect } from "react";
import {
  FaPlus,
  FaEllipsisH,
  FiEdit3,
  FiEye,
  FiTrash2,
  FiX,
} from "react-icons/all";

import { useSelector, useDispatch } from "react-redux";

import language from "./language.json";

import {
  Col5,
  Col12,
  Box,
  BoxContent,
  BoxTitle,
  Icon,
  BtnPrimaryCircle,
  Title,
  Table,
  TBody,
  Th,
  Tr,
  Td,
  Row,
  MenuActions,
  BoxFooter,
  NoData,
  SideContent,
  ListItem,
  ListLabel,
  ListTitle,
  CloseBtn,
  Strong,
  SideContentMenu,
  BtnCircle,
  SideContentContainer,
  SideContentHeader,
} from "~/styles/elements";

import { ProfileImg } from "./styles";

import Container from "~/components/Container";
import SearchBar from "~/components/SearchBar";
import Modal from "~/components/Modal";
import Input from "~/components/Input";
import DropDown from "~/components/DropDown";
import Alert from "~/components/Alert";
import Loader from "~/components/Loader";
import Pagination from "~/components/Pagination";

import DefaultProfile from "~/assets/img/user.png";

import {
  getUsersRequest,
  storeUserRequest,
  updateUserRequest,
  searchUsersRequest,
  deleteUserRequest,
} from "~/store/modules/users/actions";

import api from "~/services/api";

import { isEmail, clearPhone, fullDate } from "~/libraries/utils";

function Users() {
  const dispatch = useDispatch();

  const languages = [
    {
      id: 1,
      name: "Português - BR",
    },
    {
      id: 2,
      name: "English - USA",
    },
  ];

  const { users, pages, loading } = useSelector((state) => state.users);
  const { profile } = useSelector((state) => state.profile);

  const [query, setQuery] = useState("");

  const { lang } = profile;

  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const getRoles = async () => {
      const { data } = await api.get("v2/roles");

      const rolesList = data?.roles.filter(
        (item) => item?.id > profile?.role?.id
      );
      const roleList = rolesList?.map((r) => ({
        id: r?.id,
        name: r[`name_${lang}`],
      }));

      setRoles(roleList || []);
    };
    getRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [page, setPage] = useState(1);

  const [user, setUser] = useState(null);

  const [openModal, setOpenModal] = useState(false);

  const [name, setName] = useState("");
  const [nameValidation, setNameValidation] = useState(true);

  const [mail, setMail] = useState("");
  const [mailValidation, setMailValidation] = useState(true);

  const [phone, setPhone] = useState("");
  const [phoneValidation, setPhoneValidation] = useState(true);

  const [id_role, setRole] = useState(null);
  const [roleValidation, setRoleValidation] = useState(true);

  const [id_language, setLang] = useState(null);
  const [langValidation, setLangValidation] = useState(true);

  const validate = () => {
    let validated = true;
    if (!name) {
      setNameValidation(false);
      validated = false;
    }
    if (!mail || !isEmail(mail)) {
      setMailValidation(false);
      validated = false;
    }
    if (!phone || clearPhone(phone).length < 10) {
      setPhoneValidation(false);
      validated = false;
    }
    if (!id_role) {
      setRoleValidation(false);
      validated = false;
    }
    if (!id_language) {
      setLangValidation(false);
      validated = false;
    }

    return validated;
  };

  const store = () => {
    setNameValidation(true);
    setMailValidation(true);
    setPhoneValidation(true);
    setRoleValidation(true);
    setLangValidation(true);
    const validated = validate();
    if (validated) {
      dispatch(
        storeUserRequest({
          name,
          mail,
          phone,
          id_role,
          id_language,
        })
      );
      setOpenModal(false);
    }
  };

  const update = () => {
    setNameValidation(true);
    setMailValidation(true);
    setPhoneValidation(true);
    setRoleValidation(true);
    setLangValidation(true);
    const validated = validate();
    if (validated) {
      dispatch(
        updateUserRequest({
          id: user?.id,
          name,
          mail,
          phone,
          id_role,
          id_language,
        })
      );
      setOpenModal(false);
    }
  };

  const search = (queryterm, querypage = 1) => {
    dispatch(searchUsersRequest(queryterm, querypage));
  };

  const [showMore, setShowMore] = useState(null);

  const More = (item) => {
    if (showMore === item) {
      setShowMore(null);
    } else {
      setShowMore(item);
    }
  };

  const [view, setView] = useState(false);

  const insertUser = (userData) => {
    setName(userData?.name);
    setMail(userData?.mail);
    setPhone(userData?.phone);
    setRole(userData?.role?.id);
    setLang(userData?.lang?.id);
    setShowMore(false);
  };

  const edit = (i) => {
    setUser(users[i]);
    insertUser(users[i]);
    setOpenModal(true);
  };

  const show = (i) => {
    setUser(users[i]);
    insertUser(users[i]);
    setView(true);
  };

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  useEffect(() => {
    if (!showDeleteAlert) setUser(null);
  }, [showDeleteAlert]);

  const preDelete = (i) => {
    setUser(users[i]);
    setShowMore(null);
    setShowDeleteAlert(true);
  };

  const del = (confirm) => {
    if (confirm) dispatch(deleteUserRequest(user?.id));
  };

  useEffect(() => {
    if (!query) {
      dispatch(getUsersRequest(page));
    } else {
      search(query, page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    setShowMore(null);
    if (!openModal && !view && !showDeleteAlert) {
      setName("");
      setMail("");
      setPhone("");
      setRole(null);
      setLang(null);
      setUser(null);
    }
  }, [openModal, view, showDeleteAlert]);

  return (
    <Container>
      <Row>
        <Col12>
          <Box>
            <BoxTitle>
              <Title>{language[lang].title}</Title>
              <Col5 isInput>
                <SearchBar
                  placeholder={language[lang]?.search}
                  onSearch={search}
                  onClear={() => {
                    setQuery("");
                    dispatch(getUsersRequest(1));
                  }}
                  onChange={setQuery}
                />
                <BtnPrimaryCircle onClick={() => setOpenModal(true)}>
                  <FaPlus />
                </BtnPrimaryCircle>
              </Col5>
            </BoxTitle>
            <BoxContent>
              <Table>
                <Tr>
                  <Th>{language[lang].table.name}</Th>
                  <Th>{language[lang].table.mail}</Th>
                  <Th>{language[lang].table.phone}</Th>
                  <Th>{language[lang].table.role}</Th>
                  <Th>{language[lang].table.lastAccess}</Th>
                  <Th>{language[lang].table.createdAt}</Th>
                  <Th />
                </Tr>
                <TBody>
                  {users.length > 0 ? (
                    users.map((u, i) => (
                      <Tr key={`user-${u?.id}`}>
                        <Td>{u?.name}</Td>
                        <Td>{u?.mail}</Td>
                        <Td>{u?.phone}</Td>
                        <Td>{u?.role[`name_${lang}`]}</Td>
                        <Td>{fullDate(u?.last_access, lang)}</Td>
                        <Td>{fullDate(u?.created_at, lang)}</Td>
                        <Td className="actions">
                          <BtnPrimaryCircle isGrey onClick={() => More(i)}>
                            <FaEllipsisH />
                          </BtnPrimaryCircle>
                          {showMore === i && (
                            <MenuActions>
                              {/* <Icon onClick={() => setShowDisable(true)}>
                                <FiPower />
                              </Icon> */}
                              <Icon onClick={() => edit(i)}>
                                <FiEdit3 />
                              </Icon>
                              <Icon onClick={() => show(i)}>
                                <FiEye />
                              </Icon>
                              <Icon
                                className="last"
                                onClick={() => preDelete(i)}
                              >
                                <FiTrash2 />
                              </Icon>
                            </MenuActions>
                          )}
                        </Td>
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan="7">
                        <NoData>
                          {query
                            ? language[lang].noDataQ
                            : language[lang].noData}
                        </NoData>
                      </Td>
                    </Tr>
                  )}
                </TBody>
              </Table>
            </BoxContent>
            <BoxFooter right noBorder>
              <Pagination page={page} pages={pages} onPaginate={setPage} />
            </BoxFooter>
          </Box>
        </Col12>
      </Row>
      <Modal
        open={openModal}
        modalTitle={
          user
            ? language[lang]?.modalTitle?.update
            : language[lang]?.modalTitle?.create
        }
        onCancel={() => setOpenModal(false)}
        cancelText={language[lang].button.cancel}
        confirmText={language[lang].button.save}
        onConfirm={user ? update : store}
      >
        <Col5>
          <Input
            placeholder={language[lang]?.form?.name?.placeholder}
            label={language[lang]?.form?.name?.placeholder}
            validation={nameValidation}
            validationText={language[lang]?.form?.name?.error}
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
          <Input
            placeholder={language[lang]?.form?.mail?.placeholder}
            label={language[lang]?.form?.mail?.placeholder}
            validation={mailValidation}
            validationText={language[lang]?.form?.mail?.error}
            onChange={(e) => setMail(e.target.value)}
            value={mail}
          />
          <Input
            mask="(99) 9 9999-9999"
            placeholder={language[lang]?.form?.phone?.placeholder}
            label={language[lang]?.form?.phone?.placeholder}
            validation={phoneValidation}
            validationText={language[lang]?.form?.phone?.error}
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
          />
          {id_language ? (
            <DropDown
              placeholder={language[lang]?.form?.language?.placeholder}
              data={languages}
              label={language[lang]?.form?.language?.placeholder}
              validation={langValidation}
              validationText={language[lang]?.form?.language?.error}
              emptyText={language[lang]?.form?.language?.empty}
              onChange={(e) => setLang(e.id)}
              defaultValue={id_language}
            />
          ) : (
            <DropDown
              placeholder={language[lang]?.form?.language?.placeholder}
              data={languages}
              label={language[lang]?.form?.language?.placeholder}
              validation={langValidation}
              validationText={language[lang]?.form?.language?.error}
              emptyText={language[lang]?.form?.language?.empty}
              onChange={(e) => setLang(e.id)}
            />
          )}
          {id_role ? (
            <DropDown
              placeholder={language[lang]?.form?.role?.placeholder}
              data={roles}
              label={language[lang]?.form?.role?.placeholder}
              validation={roleValidation}
              validationText={language[lang]?.form?.role?.error}
              emptyText={language[lang]?.form?.role?.empty}
              onChange={(e) => setRole(e.id)}
              defaultValue={id_role}
            />
          ) : (
            <DropDown
              placeholder={language[lang]?.form?.role?.placeholder}
              data={roles}
              label={language[lang]?.form?.role?.placeholder}
              validation={roleValidation}
              validationText={language[lang]?.form?.role?.error}
              emptyText={language[lang]?.form?.role?.empty}
              onChange={(e) => setRole(e.id)}
            />
          )}
        </Col5>
      </Modal>
      <Alert
        open={showDeleteAlert}
        onClose={() => setShowDeleteAlert(false)}
        onClick={(value) => del(value)}
        title={language[lang]?.alerts?.delete?.title}
        text={language[lang]?.alerts?.delete?.text}
        type="error"
        buttons={[
          {
            text: language[lang]?.alerts?.delete?.btn?.confirm,
            closeModal: true,
            value: true,
          },
          {
            text: language[lang]?.alerts?.delete?.btn?.cancel,
            gray: true,
            closeModal: true,
            value: false,
          },
        ]}
      />
      {view && (
        <SideContent>
          <CloseBtn onClick={() => setView(false)}>
            <FiX />
          </CloseBtn>
          <SideContentHeader>
            <ProfileImg src={user?.avatar?.path || DefaultProfile} />
            <ListTitle>{user?.name}</ListTitle>
          </SideContentHeader>
          <SideContentContainer>
            <ListItem>
              <Strong>{language[lang]?.sideContent?.mail}:</Strong> {user?.mail}
            </ListItem>
            <ListItem>
              <Strong>{language[lang]?.sideContent?.phone}:</Strong>{" "}
              {user?.phone}
            </ListItem>
            <ListItem>
              <Strong>{language[lang]?.sideContent?.role}:</Strong>{" "}
              {user?.role[`name_${lang}`]}
            </ListItem>
            <ListItem>
              <Strong>{language[lang]?.sideContent?.language}:</Strong>{" "}
              {user?.lang?.name}
            </ListItem>

            <ListLabel>{language[lang]?.sideContent?.more}</ListLabel>
            <ListItem>
              <Strong>{language[lang]?.sideContent?.lastAccess}:</Strong>
              {fullDate(user?.last_access, lang)}
            </ListItem>
            <ListItem>
              <Strong>{language[lang]?.sideContent?.createdAt}:</Strong>{" "}
              {fullDate(user?.created_at, lang)}
            </ListItem>
            <ListItem>
              <Strong>{language[lang]?.sideContent?.by}:</Strong>{" "}
              {user?.by?.name}
            </ListItem>
          </SideContentContainer>
          <SideContentMenu>
            <BtnCircle
              onClick={() => {
                setView(false);
                setOpenModal(true);
              }}
            >
              <FiEdit3 />
            </BtnCircle>
            <BtnCircle
              onClick={() => {
                setView(false);
                setShowDeleteAlert(true);
              }}
            >
              <FiTrash2 />
            </BtnCircle>
          </SideContentMenu>
        </SideContent>
      )}
      <Loader show={loading} />
    </Container>
  );
}

export default Users;
