import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

export const Content = styled.div`
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const FormContainer = styled.div`
  width: calc(100% - 82px);
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const InputText = styled.input`
  width: 100%;
  height: 40px;
  border: none;
  border-bottom: ${(props) =>
    !props.validation ? "1px solid #da1e1e" : "1px solid #5271c4"};
  padding: 5px;
  color: #333;
  margin-bottom: 3px;
`;

export const Label = styled.label`
  font-size: 12px;
  font-weight: 600;
  color: ${(props) => (!props.validation ? "#da1e1e" : "#333333")};
`;

export const Error = styled.span`
  color: #da1e1e;
  font-size: 11px;
`;

export const At = styled.span`
  width: 4%;
  text-align: center;
`;
