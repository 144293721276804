import { combineReducers } from "redux";

import auth from "./auth/reducer";
import dashboard from "./dashboard/reducer";
import users from "./users/reducer";
import profile from "./profile/reducer";
import clients from "./clients/reducer";
import travels from "./travels/reducer";
import tasks from "./tasks/reducer";
import hotels from "./hotels/reducer";

export default combineReducers({
  auth,
  dashboard,
  users,
  profile,
  clients,
  travels,
  tasks,
  hotels,
});
