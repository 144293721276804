/* eslint-disable import/no-cycle */
import { call, put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

import api from "../../../services/api";
import {
  userError,
  updateUserSuccess,
  getUsersSuccess,
  storeUserSuccess,
  searchUsersSuccess,
  deleteUserSuccess,
} from "./actions";

import { store as state } from "~/store";

function* get({ payload: { page } }) {
  try {
    const { data } = yield call(api.get, `/v2/agencies/users/${page}`);
    if (data.error) throw new Error(data.error);

    yield put(getUsersSuccess(data));
  } catch (e) {
    toast.error(e.message);
    yield put(userError());
  }
}

function* store({ payload }) {
  try {
    const { data } = yield call(api.post, "v2/agencies/users", payload);
    if (data.error) throw new Error(data.error);

    yield put(storeUserSuccess(data));
    const { lang } = state.getState().profile.profile;

    toast.success(
      lang === "en"
        ? "User created successfully "
        : "Usuário criado com sucesso!"
    );
  } catch (e) {
    toast.error(e.message);
    yield put(userError());
  }
}

function* search({ payload }) {
  try {
    const { query, page } = payload;

    const { data } = yield call(
      api.get,
      `v2/agencies/users/search/${query}/${page}`
    );
    if (data.error) throw new Error(data.error);

    yield put(searchUsersSuccess(data));
  } catch (e) {
    toast.error(e.message);
    yield put(userError());
  }
}

function* del({ payload }) {
  try {
    const { data } = yield call(api.delete, `v2/agencies/users/${payload.id}`);
    if (data.error) throw new Error(data.error);

    yield put(deleteUserSuccess(data));

    const { lang } = state.getState().profile.profile;

    toast.success(
      lang === "en"
        ? "User deleted successfully "
        : "Usuário excluído com sucesso!"
    );
  } catch (e) {
    toast.error(e.message);
    yield put(userError());
  }
}

function* update({ payload }) {
  try {
    const { data } = yield call(api.put, "v2/agencies/users", payload);
    if (data.error) throw new Error(data.error);

    const { lang } = state.getState().profile.profile;

    yield put(updateUserSuccess(data));
    toast.success(
      lang === "en"
        ? "User updated successfully "
        : "Usuário alterado com sucesso!"
    );
  } catch (e) {
    toast.error(e.message);
    yield put(userError());
  }
}

export default all([
  takeLatest("@user/get_REQUEST", get),
  takeLatest("@user/delete_REQUEST", del),
  takeLatest("@user/search_REQUEST", search),
  takeLatest("@user/store_REQUEST", store),
  takeLatest("@user/update_REQUEST", update),
]);
