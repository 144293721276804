import produce from "immer";

const INITIAL_STATE = {
  tasks: [],
  loading: false,
};

export default (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case "@tasks/get_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@tasks/get_SUCCESS": {
        draft.loading = false;
        draft.tasks = action.payload.tasks;
        break;
      }
      case "@tasks/store_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@tasks/store_SUCCESS": {
        draft.loading = false;
        draft.tasks = action.payload.tasks;
        break;
      }
      case "@tasks/delete_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@tasks/delete_SUCCESS": {
        draft.loading = false;
        draft.tasks = action.payload.tasks;
        break;
      }
      case "@tasks/end_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@tasks/end_SUCCESS": {
        draft.loading = false;
        draft.tasks = action.payload.tasks;
        break;
      }
      case "@tasks/update_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@tasks/update_SUCCESS": {
        draft.loading = false;
        draft.tasks = action.payload.tasks;
        break;
      }
      case "@tasks/error": {
        draft.loading = false;
        break;
      }
      default:
        break;
    }
  });
