/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { FiCheck, FiTrash2, FiX, FiPlus } from "react-icons/fi";

import language from "./language.json";

import { Container, Content, FormContainer, Error } from "./styles";
import { BtnPrimaryCircle } from "~/styles/elements";

import InputText from "~/components/Input";

function AddRoom({ data, onEdit, onDelete, onAdd, validation }) {
  const { lang } = useSelector((state) => state.profile.profile);

  const [room, setRoom] = useState("");
  const [capacity, setCapacity] = useState("");
  const [edited, setEdited] = useState(false);

  const [roomValidation, setRoomValidation] = useState(true);
  const [capacityValidation, setCapacityValidation] = useState(true);

  useEffect(() => {
    if (!validation) {
      setRoomValidation(false);
    }
  }, [validation]);

  const Validate = () => {
    let validated = true;

    if (!room) {
      setRoomValidation(false);
      validated = false;
    }
    if (!capacity) {
      setCapacityValidation(false);
      validated = false;
    }

    return validated;
  };

  const Cancel = () => {
    setEdited(false);
    setRoom(data?.room || "");
  };

  const Save = () => {
    setRoomValidation(true);
    setCapacityValidation(true);

    const validated = Validate();

    if (validated) {
      onAdd({ room, capacity });
      Cancel();
    }
  };

  useEffect(() => {
    if (data) {
      setRoom(data?.room);
    }
  }, [data]);

  return (
    <Container>
      <Content>
        <FormContainer>
          <InputText
            isBoxed
            style={{ width: "100%", marginRight: 5 }}
            value={room}
            onChange={(e) => {
              setEdited(true);
              setRoom(e.target.value);
            }}
            placeholder={language[lang].placeholder.room}
            validation={roomValidation}
          />
          <InputText
            isBoxed
            style={{ width: 105 }}
            value={capacity}
            onChange={(e) => {
              setEdited(true);
              setCapacity(e.target.value);
            }}
            placeholder={language[lang].placeholder.capacity}
            validation={capacityValidation}
          />
        </FormContainer>
        {data ? (
          <>
            {!edited ? (
              <>
                <BtnPrimaryCircle
                  style={{ marginLeft: 5 }}
                  onClick={() => onDelete()}
                >
                  <FiTrash2 />
                </BtnPrimaryCircle>
              </>
            ) : (
              <>
                <BtnPrimaryCircle
                  style={{ marginLeft: 5 }}
                  onClick={() => {
                    onEdit({ room });
                    setEdited(false);
                  }}
                >
                  <FiCheck />
                </BtnPrimaryCircle>
                <BtnPrimaryCircle
                  style={{ marginLeft: 5 }}
                  onClick={() => Cancel()}
                >
                  <FiX />
                </BtnPrimaryCircle>
              </>
            )}
          </>
        ) : (
          <BtnPrimaryCircle style={{ marginLeft: 5 }} onClick={() => Save()}>
            <FiPlus />
          </BtnPrimaryCircle>
        )}
      </Content>
      {!roomValidation || !capacityValidation ? (
        <Error>{`${
          (!roomValidation && `${language[lang].validation.room}`) ||
          (!capacityValidation && `${language[lang].validation.capacity}`)
        }`}</Error>
      ) : (
        ""
      )}
    </Container>
  );
}

AddRoom.defaultProps = {
  data: null,
  onEdit: () => {},
  onDelete: () => {},
  onAdd: () => {},
  validation: true,
};

AddRoom.propTypes = {
  data: PropTypes.object,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onAdd: PropTypes.func,
  validation: PropTypes.bool,
};

export default AddRoom;
