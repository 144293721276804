import React from "react";

import PropTypes from "prop-types";
import { Container } from "./styles";

import {
  Box,
  BoxTitle,
  Title,
  BtnNoBox,
  BoxContent,
  BoxFooter,
  BtnPrimary,
} from "~/styles/elements";

function Modal({
  children,
  open,
  cancelText,
  onCancel,
  confirmText,
  onConfirm,
  modalTitle,
  showFooter,
}) {
  return (
    open && (
      <Container>
        <Box style={{ height: "100%" }}>
          <BoxTitle style={{ height: 80 }}>
            <Title>{modalTitle}</Title>
            <BtnNoBox onClick={onCancel} noMargin>
              {cancelText}
            </BtnNoBox>
          </BoxTitle>
          <BoxContent
            style={{
              flex: 1,
              overflowY: "auto",
              height: "calc(100% - 160px)",
            }}
          >
            {children}
          </BoxContent>
          {showFooter && (
            <BoxFooter style={{ height: 80 }}>
              <BtnPrimary onClick={onConfirm} noMargin>
                {confirmText}
              </BtnPrimary>
            </BoxFooter>
          )}
        </Box>
      </Container>
    )
  );
}

Modal.defaultProps = {
  open: false,
  cancelText: "Cancelar",
  confirmText: "Salvar",
  onConfirm: () => {},
  onCancel: () => {},
  modalTitle: "Título do Modal",
  showFooter: true,
};

Modal.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool,
  cancelText: PropTypes.string,
  onCancel: PropTypes.func,
  confirmText: PropTypes.string,
  onConfirm: PropTypes.func,
  modalTitle: PropTypes.string,
  showFooter: PropTypes.bool,
};

export default Modal;
