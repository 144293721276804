import React from "react";
import PropTypes from "prop-types";
import DefaultProfile from "~/assets/img/user.png";

import { Container } from "./styles";

export default function Avatar({ onChange, preview }) {
  return (
    <Container>
      <label htmlFor="avatar">
        <img src={preview || DefaultProfile} alt="" />

        <input type="file" id="avatar" accept="image/*" onChange={onChange} />
      </label>
    </Container>
  );
}

Avatar.defaultProps = {
  preview: "",
};

Avatar.propTypes = {
  onChange: PropTypes.func.isRequired,
  preview: PropTypes.string,
};
