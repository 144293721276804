import React from "react";
import { Switch, Router } from "react-router-dom";
import Route from "./Route";

import history from "~/services/history";

import SignIn from "~/pages/SignIn";
import ForgotPassword from "~/pages/ForgotPassword";
import Dashboard from "~/pages/Dashboard";
import Clients from "~/pages/Clients";
import Travels from "~/pages/Travels";
import Hotels from "~/pages/Library/Hotels";
import Users from "~/pages/Settings/Users";
import Config from "~/pages/Settings/Config";
import Agency from "~/pages/Settings/Agency";

function Routes() {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/" exact component={SignIn} />
        <Route path="/forgot" component={ForgotPassword} />
        <Route path="/dashboard" component={Dashboard} isPrivate />
        <Route path="/clients" component={Clients} isPrivate />
        <Route path="/travels" component={Travels} isPrivate />
        <Route path="/hotels" component={Hotels} isPrivate />
        <Route path="/users" component={Users} isPrivate />
        <Route path="/settings" component={Config} isPrivate />
        <Route path="/agency" component={Agency} isPrivate />
        <Route path="/notfound" component={() => <h1>404</h1>} />
      </Switch>
    </Router>
  );
}

export default Routes;
