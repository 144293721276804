export function getTasksRequest() {
  return {
    type: "@tasks/get_REQUEST",
  };
}

export function getTasksSuccess({ tasks }) {
  return {
    type: "@tasks/get_SUCCESS",
    payload: { tasks },
  };
}

export function storeTaskRequest({ title, content }) {
  return {
    type: "@tasks/store_REQUEST",
    payload: { title, content },
  };
}

export function storeTaskSuccess({ tasks }) {
  return {
    type: "@tasks/store_SUCCESS",
    payload: { tasks },
  };
}

export function updateTaskRequest({ id, title, content }) {
  return {
    type: "@tasks/update_REQUEST",
    payload: { id, title, content },
  };
}

export function updateTaskSuccess({ tasks }) {
  return {
    type: "@tasks/update_SUCCESS",
    payload: { tasks },
  };
}

export function deleteTaskRequest(id) {
  return {
    type: "@tasks/delete_REQUEST",
    payload: { id },
  };
}

export function deleteTaskSuccess({ tasks }) {
  return {
    type: "@tasks/delete_SUCCESS",
    payload: { tasks },
  };
}

export function endTaskRequest(id) {
  return {
    type: "@tasks/end_REQUEST",
    payload: { id },
  };
}

export function endTaskSuccess({ tasks }) {
  return {
    type: "@tasks/end_SUCCESS",
    payload: { tasks },
  };
}

export function taskError() {
  return {
    type: "@tasks/error",
  };
}
