import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  FaPlus,
  FaEllipsisH,
  FiEdit3,
  FiEye,
  FiTrash2,
  FiX,
  FaHistory,
} from "react-icons/all";

import {
  getListRequest,
  saveNewTravelRequest,
  updateTravelRequest,
  deleteTravelRequest,
  searchTravelRequest,
} from "~/store/modules/travels/actions";

import Container from "~/components/Container";
import { InfoItem } from "~/components/SideModal/styles";
import {
  Col5,
  Col6,
  Col12,
  Box,
  BoxContent,
  BoxTitle,
  Icon,
  BtnPrimaryCircle,
  Title,
  Table,
  TBody,
  Th,
  Tr,
  Td,
  Row,
  MenuActions,
  BoxFooter,
  SideContent,
  SideContentContainer,
  CloseBtn,
  SideContentHeader,
  ListTitle,
  ListItem,
  Strong,
  SideContentMenu,
  BtnCircle,
  NoData,
  Tab,
  TabMenu,
  Gallery,
} from "~/styles/elements";
import { ProfileImg } from "~/pages/Settings/Users/styles";

import SearchBar from "~/components/SearchBar";
import Modal from "~/components/Modal";
import Input from "~/components/Input";
import Alert from "~/components/Alert";
import Pagination from "~/components/Pagination";
import Loader from "~/components/Loader";
import DestinationSelect from "./components/DestinationSelect";
import UploadImage from "~/components/UploadImage";

import { fullDate } from "~/libraries/utils";

import DefaultProfile from "~/assets/img/user.png";

import language from "./language.json";

function Travels() {
  const dispatch = useDispatch();

  const { list, pages, loading } = useSelector((state) => state.travels);

  const { lang } = useSelector((state) => state.profile.profile);

  const [page, setPage] = useState(1);
  useEffect(() => {
    dispatch(getListRequest(page));
  }, [page]);

  const [query, setQuery] = useState("");
  const search = (queryterm, querypage = 1) => {
    dispatch(searchTravelRequest(queryterm, querypage));
  };
  useEffect(() => {
    if (!query) {
      dispatch(getListRequest(page));
    } else {
      search(query, page);
    }
  }, [page]);

  const [travelName, setTravelName] = useState("");
  const [nameValidation, setNameValidation] = useState(true);

  const [travel, setTravel] = useState(null);
  const [showTravel, setShowTravel] = useState(false);
  const [showSideModal, setShowSideModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showMore, setShowMore] = useState(null);

  useEffect(() => {
    if (showTravel || showDelete || showSideModal) {
      setShowMore(false);
    }

    if (showTravel) {
      setShowSideModal(false);
    }
  }, [showTravel, showDelete, showSideModal]);

  const More = (item) => {
    if (showMore === item) {
      setShowMore(null);
    } else {
      setShowMore(item);
    }
  };

  const [destinations, setDestinations] = useState([]);
  const [destinationsValidation, setDestinationsValidation] = useState(true);

  const Validate = () => {
    let validated = true;

    if (!travelName) {
      setNameValidation(false);
      validated = false;
    }

    if (destinations?.length === 0) {
      setDestinationsValidation(false);
      validated = false;
    }

    return validated;
  };

  const AddClick = ({ city, start_date, end_date }) => {
    setDestinations([...destinations, { city, start_date, end_date }]);
  };

  const RemoveClick = (index) => {
    const removeInputs = [...destinations];
    removeInputs.splice(index, 1);
    setDestinations(removeInputs);
  };

  const EditClick = (data, index) => {
    const newInput = [...destinations];
    newInput[index] = { ...data };
    setDestinations(newInput);
  };

  useEffect(() => {
    if (!showTravel && !showSideModal && !showDelete) {
      setTravel(null);
      setTravelName("");
      setDestinations([]);
      setNameValidation(true);
      setDestinationsValidation(true);
    }
  }, [showTravel, showSideModal, showDelete]);
  const View = (i) => {
    setTravel({ data: list[i], index: i });
    setShowSideModal(true);
  };

  useEffect(() => {
    if (travel) {
      setTravelName(travel?.name);
    }
  }, [travel]);

  const SaveOrUpdate = (id) => {
    setNameValidation(true);
    setDestinationsValidation(true);

    const validated = Validate();

    if (validated) {
      dispatch(
        !travel
          ? saveNewTravelRequest(travelName)
          : updateTravelRequest(id, travelName)
      );
      setShowTravel(false);
    }
  };

  const Edit = (i) => {
    setTravel(list[i]);
    setShowTravel(true);
  };

  const PreDelete = (i) => {
    setTravel({ data: list[i], index: i });
    setShowMore(null);
    setShowSideModal(false);
    setShowDelete(true);
  };

  const Del = (confirm) => {
    if (confirm) dispatch(deleteTravelRequest(travel?.data?.id));
  };

  const [tab, setTab] = useState("published");

  const [images, setImages] = useState([]);

  const RemoveImage = (index) => {
    const removeImages = [...images];
    removeImages.splice(index, 1);
    setImages(removeImages);
  };

  const AddImage = (img) => {
    if (img) {
      setImages([...images, img]);
    }
  };

  return (
    <Container>
      <Row>
        <Col12>
          <Box>
            <BoxTitle>
              <Title>{language[lang].title.travels}</Title>
              <Col5 isInput>
                <SearchBar
                  placeholder={language[lang].input.search}
                  onSearch={search}
                  onClear={() => {
                    setQuery("");
                    dispatch(getListRequest(1));
                  }}
                  onChange={setQuery}
                />
                <BtnPrimaryCircle onClick={() => setShowTravel(true)}>
                  <FaPlus />
                </BtnPrimaryCircle>
              </Col5>
            </BoxTitle>
            <BoxContent>
              <TabMenu>
                <Tab
                  active={tab === "published"}
                  onClick={() => setTab("published")}
                >
                  {language[lang].tab.published}
                </Tab>
                <Tab
                  active={tab === "unpublished"}
                  onClick={() => setTab("unpublished")}
                >
                  {language[lang].tab.unpublished}
                </Tab>
                <Tab
                  active={tab === "inProgress"}
                  onClick={() => setTab("inProgress")}
                >
                  {language[lang].tab.inProgress}
                </Tab>
                <Tab
                  active={tab === "execute"}
                  onClick={() => setTab("execute")}
                >
                  {language[lang].tab.execute}
                </Tab>
              </TabMenu>
              <Table>
                <Tr>
                  <Th>#</Th>
                  <Th>{language[lang].table.name}</Th>
                  <Th>{language[lang].table.destiny}</Th>
                  <Th>{language[lang].table.passengers}</Th>
                  <Th>{language[lang].table.startDate}</Th>
                  <Th>{language[lang].table.endDate}</Th>
                  <Th />
                </Tr>
                <TBody>
                  {list?.length > 0 ? (
                    list?.map((a, i) => (
                      <Tr key={`travel-${a?.id}`}>
                        <Td>{a?.code}</Td>
                        <Td>{a?.name}</Td>
                        <Td>{a?.destinies}</Td>
                        <Td>{a?.qtdPassengers}</Td>
                        <Td>{a?.startDate}</Td>
                        <Td>{a?.endDate}</Td>
                        <Td className="actions">
                          {" "}
                          <BtnPrimaryCircle isGrey onClick={() => More(i)}>
                            <FaEllipsisH />
                          </BtnPrimaryCircle>
                          {showMore === i && (
                            <MenuActions>
                              <Icon onClick={() => Edit(i)}>
                                <FiEdit3 />
                              </Icon>
                              <Icon onClick={() => View(i)}>
                                <FiEye />
                              </Icon>
                              <Icon
                                className="last"
                                onClick={() => PreDelete(i)}
                              >
                                <FiTrash2 />
                              </Icon>
                            </MenuActions>
                          )}
                        </Td>
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan="7">
                        {loading ? (
                          <NoData>{language[lang].loading}</NoData>
                        ) : (
                          <NoData>{language[lang].noData}</NoData>
                        )}
                      </Td>
                    </Tr>
                  )}
                </TBody>
              </Table>
            </BoxContent>
            <BoxFooter right noBorder>
              <Pagination page={page} pages={pages} onPaginate={setPage} />
            </BoxFooter>
          </Box>
        </Col12>
      </Row>
      <Modal
        open={showTravel}
        modalTitle={
          !travel
            ? language[lang].title.newTravel
            : language[lang].title.editTravel
        }
        onCancel={() => setShowTravel(false)}
        cancelText={language[lang].button.cancel}
        confirmText={language[lang].button.save}
        onConfirm={() => SaveOrUpdate(travel?.id)}
      >
        <Col5>
          <Input
            placeholder={language[lang].input.placeholder.name}
            label={language[lang].input.label.name}
            validationText={language[lang].validation.name}
            validation={nameValidation}
            value={travelName}
            onChange={(e) => setTravelName(e.target.value)}
          />
          {destinations?.map((d, i) => (
            <DestinationSelect
              data={d}
              onEdit={(data) => EditClick(data, i)}
              onDelete={() => RemoveClick(i)}
            />
          ))}
          <DestinationSelect
            onAdd={AddClick}
            validation={destinationsValidation}
          />
        </Col5>
        <Col6>
          <Gallery>
            {images?.map((img, i) => (
              <UploadImage onDelete={() => RemoveImage(i)} image={img} />
            ))}
            <UploadImage onAdd={(image) => AddImage(image)} />
          </Gallery>
        </Col6>
      </Modal>
      <Alert
        open={showDelete}
        onClose={() => setShowDelete(false)}
        onClick={(value) => Del(value)}
        title={language[lang].alert.title}
        text={language[lang].alert.delete}
        type="error"
        buttons={[
          {
            text: language[lang].button.delete,
            closeModal: true,
            value: true,
          },
          {
            text: language[lang].button.cancel,
            gray: true,
            closeModal: true,
            value: false,
          },
        ]}
      />
      {showSideModal && (
        <SideContent>
          <CloseBtn onClick={() => setShowSideModal(false)}>
            <FiX />
          </CloseBtn>
          <SideContentHeader>
            <ProfileImg src={travel?.avatar?.path || DefaultProfile} />
            <ListTitle>{travel?.data?.name}</ListTitle>
          </SideContentHeader>
          <SideContentContainer>
            <ListItem>
              <Strong>{language[lang]?.sideModal?.email}:</Strong>{" "}
              {travel?.data?.mail}
            </ListItem>
            <ListItem>
              <Strong>{language[lang]?.sideModal?.phone}:</Strong>{" "}
              {travel?.data?.phone}
            </ListItem>
            {lang === "br" && (
              <ListItem>
                <Strong>{language[lang]?.sideModal?.cpf}:</Strong>{" "}
                {travel?.data?.cpf}
              </ListItem>
            )}
            <ListItem>
              <Strong>{language[lang]?.sideModal?.id}:</Strong>{" "}
              {travel?.data?.idClient}
            </ListItem>
            <ListItem>
              <Strong>{language[lang]?.sideModal?.lastAccess}:</Strong>{" "}
              {fullDate(travel?.data?.lastAccess, lang)}
            </ListItem>
            <ListItem>
              <Strong>{language[lang]?.sideModal?.currentTravels}:</Strong>{" "}
              {travel?.data?.currentTravels}
            </ListItem>
            <ListItem>
              <Strong>{language[lang]?.sideModal?.futureTravels}:</Strong>{" "}
              {travel?.data?.futureTravels}
            </ListItem>
            <ListItem>
              <Strong>{language[lang]?.sideModal?.progressTravels}</Strong>{" "}
              <SideContentContainer style={{ padding: " 10px 0px" }}>
                <InfoItem>{travel?.data?.travelName}</InfoItem>
                <ListItem style={{ fontSize: 11 }}>
                  Rio de Janeiro + 5 destinos
                </ListItem>
              </SideContentContainer>
            </ListItem>
          </SideContentContainer>
          <SideContentMenu>
            <BtnCircle
              onClick={() => {
                Edit(travel?.index);
              }}
            >
              <FiEdit3 />
            </BtnCircle>
            <BtnCircle onClick={() => {}}>
              <FaHistory />
            </BtnCircle>
            <BtnCircle
              onClick={() => {
                PreDelete(travel?.index);
              }}
            >
              <FiTrash2 />
            </BtnCircle>
          </SideContentMenu>
        </SideContent>
      )}
      <Loader show={loading} />
    </Container>
  );
}

export default Travels;
