import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 7px;
  min-height: 80px;
  padding: 20px;
  box-shadow: 0 2px 2px -2px ${(e) => e.theme?.secondaryColor}4d;
`;

export const Title = styled.h4`
  color: ${(e) => e.theme?.secondaryColor}4d;
  font-size: 15px;
  font-family: Lato;
  font-weight: normal;
  margin-bottom: 20px;
`;

export const Value = styled.p`
  color: ${(e) => e.theme?.primaryColor};
  font-size: 48px;
  font-weight: bold;
  font-family: Lato;
  line-height: 40px;
`;
