import styled from "styled-components";

export const Container = styled.div`
  width: 60px;
  height: 60px;
  position: relative;
  cursor: pointer;
`;

export const Icon = styled.div`
  height: 45px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Img = styled.img`
  height: 30px;
  width: 30px;
  border-radius: 50%;
`;

export const ContentContainer = styled.div`
  position: absolute:
  top: 60px;
  justify-content: flex-start;
`;
