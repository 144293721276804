import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";

import PropTypes from "prop-types";

function ThemeProviderHook({ children }) {
  const { theme: customTheme } = useSelector((state) => state.profile);

  const [theme, setTheme] = useState({
    primaryColor: "#5271c4",
    secondaryColor: "#333333",
  });

  useEffect(() => {
    if (customTheme) {
      setTheme({
        primaryColor: customTheme?.primary_color,
        secondaryColor: customTheme?.secondary_color,
      });
    } else {
      setTheme({
        primaryColor: "#5271c4",
        secondaryColor: "#333333",
      });
    }
  }, [customTheme]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

ThemeProviderHook.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ThemeProviderHook;
