import React from "react";
import PropTypes from "prop-types";

import { Container } from "./styles";

import Plane from "./components/plane";

function Loader({ show }) {
  return (
    show && (
      <Container>
        <Plane />
      </Container>
    )
  );
}

Loader.defaultProps = {
  show: false,
};

Loader.propTypes = {
  show: PropTypes.bool,
};

export default Loader;
