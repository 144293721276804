import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { signInRequest } from "~/store/modules/auth/actions";

import { BtnPrimary } from "~/styles/elements";
import {
  BgAuth,
  Container,
  Title,
  BgFormAuth,
  BgLogoAuth,
  Label,
  BtnAuth,
} from "./styles";

import Input from "~/components/Input";

import { isEmail } from "~/libraries/utils";

function SignIn() {
  const dispatch = useDispatch();

  const [mail, setMail] = useState("");
  const [mailValidation, setMailValidation] = useState(true);
  const [pass, setPass] = useState("");
  const [passValidation, setPassValidation] = useState(true);

  const { loading } = useSelector((state) => state.auth);
  const { theme } = useSelector((state) => state.profile);

  const Validate = () => {
    let validated = true;

    if (!mail || !isEmail(mail)) {
      setMailValidation(false);
      validated = false;
    }

    if (!pass) {
      setPassValidation(false);
      validated = false;
    }

    return validated;
  };

  const Auth = () => {
    setMailValidation(true);
    setPassValidation(true);

    const validated = Validate();

    if (validated) dispatch(signInRequest(mail, pass));
  };

  return (
    <Container>
      <BgAuth>
        <Title colorPrimary>Seja bem-vindo</Title>
        <Label colorPrimary>Faça login para continuar</Label>
        <BgLogoAuth logo={theme?.logo} />
      </BgAuth>
      <BgFormAuth>
        <Title noBold lowMargin>
          Login
        </Title>
        <Label noBold margin>
          Insira seus dados de acesso para continuar
        </Label>
        <Input
          placeholder="Seu melhor e-mail"
          validation={mailValidation}
          validationText="Por favor insira um e-mail válido"
          value={mail}
          onChange={(e) => setMail(e.target.value)}
          isBoxed
          style={{ marginBottom: 0 }}
        />
        <Input
          placeholder="Sua senha secreta"
          type="password"
          validation={passValidation}
          validationText="Por favor insira sua senha"
          value={pass}
          onChange={(e) => setPass(e.target.value)}
          isBoxed
        />
        <BtnPrimary onClick={() => Auth()}>
          {loading ? "Entrando..." : "Entrar"}
        </BtnPrimary>
        <BtnAuth href="/forgot">Esqueceu a senha?</BtnAuth>
      </BgFormAuth>
    </Container>
  );
}

export default SignIn;
