import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import br from "~/assets/img/brazil.png";
import en from "~/assets/img/united-states.png";

import { Container, Icon, Img, ContentContainer } from "./styles";

import { changeLang } from "~/store/modules/profile/actions";

function LangMenu() {
  const dispatch = useDispatch();

  const langs = {
    br: {
      src: br,
      alt: "Português - BR",
    },
    en: {
      src: en,
      alt: "English - USA",
    },
  };

  const { lang } = useSelector((state) => state.profile.profile);

  const [showLangs, setShowLangs] = useState(false);

  const setLang = (language) => {
    dispatch(changeLang(language));
  };

  return (
    <Container onClick={() => setShowLangs(!showLangs)}>
      <Icon style={{ height: 60 }}>
        <Img
          src={langs[lang]?.src}
          alt={langs[lang]?.alt}
          title={langs[lang]?.alt}
        />
      </Icon>
      {showLangs && (
        <ContentContainer>
          <Icon>
            <Img
              onClick={() => setLang("en")}
              src={en}
              alt={langs?.en?.alt}
              title={lang?.en?.alt}
            />
          </Icon>
          <Icon>
            <Img
              onClick={() => setLang("br")}
              src={br}
              alt={langs?.br?.alt}
              title={lang?.br?.alt}
            />
          </Icon>
        </ContentContainer>
      )}
    </Container>
  );
}

export default LangMenu;
