import "dotenv/config";

export default () => {
  if (process.env.REACT_APP_NODE_ENV === "homol")
    return process.env.REACT_APP_API_HOST_HOMOL;

  if (process.env.REACT_APP_NODE_ENV === "production")
    return process.env.REACT_APP_API_HOST_PROD;

  return process.env.REACT_APP_API_HOST;
};
