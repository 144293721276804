import React, { useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { FiCheck, FiTrash2 } from "react-icons/fi";

import { getInfoDashboardRequest } from "~/store/modules/dashboard/actions";

import {
  deleteTaskRequest,
  endTaskRequest,
} from "~/store/modules/tasks/actions";

import "./styles.css";
import language from "./language.json";

import Popups from "./components/Popups";
import {
  Row,
  Col3,
  Col4,
  Col8,
  BoxContent,
  BoxTitle,
  Box,
  Title,
} from "~/styles/elements";

import Loader from "~/components/Loader";
import Container from "~/components/Container";

import {
  TaskItem,
  TaskInfo,
  TaskTitle,
  TaskContent,
  TaskAction,
  TaskActionIcon,
  TasksContainer,
} from "~/components/Container/components/TaskMenu/styles";

function Dashboard() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInfoDashboardRequest());
  }, []);

  const { data, loading } = useSelector((state) => state.dashboard);
  const { profile, theme } = useSelector((state) => state.profile);
  const { lang } = profile;
  const { tasks } = useSelector((state) => state.tasks);
  const [hovered, setHovered] = useState(null);

  const del = (id) => {
    dispatch(deleteTaskRequest(id));
  };

  const complete = (id) => {
    dispatch(endTaskRequest(id));
  };

  const Chart = () => {
    try {
      const options = {
        chart: {
          type: "line",
        },
        colors: [
          `${theme?.primary_color || "#5271c4"}`,
          `${theme?.secondary_color || "#333333"}`,
        ],
        series: [
          {
            name: language[lang].totalClients,
            data: data?.report?.travels,
          },
          {
            name: language[lang].totalTravels,
            data: data?.report?.agencies,
          },
        ],
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          categories: data?.report?.labels[lang],
        },
      };

      const chart = new ApexCharts(document.querySelector("#chart"), options);

      chart.render();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (data?.report?.labels) Chart();
  }, [data]);

  return (
    <>
      <Container>
        <Row>
          <Col3>
            <Popups
              title={language[lang]?.popup?.unpublishedTravels}
              value={data?.unpublished}
            />
          </Col3>
          <Col3>
            <Popups
              title={language[lang].popup.publishedTravels}
              value={data?.published}
            />
          </Col3>
          <Col3>
            <Popups
              title={language[lang].popup.travelsInProgress}
              value={data?.inProgress}
            />
          </Col3>
          <Col3>
            <Popups
              title={language[lang].popup.completedTravels}
              value={data?.completed}
            />
          </Col3>
        </Row>
        <Row>
          <Col8>
            <Box>
              <BoxTitle>
                <Title>{language[lang].chart}</Title>
              </BoxTitle>
              <BoxContent>
                <div id="chart" />
              </BoxContent>
            </Box>
          </Col8>
          <Col4>
            <Box>
              <BoxTitle>
                <Title>{language[lang].tasks}</Title>
              </BoxTitle>
              <BoxContent>
                <TasksContainer>
                  {tasks?.new?.length > 0 &&
                    tasks?.new?.map((n, i) => (
                      <TaskItem
                        key={`new-task-${n?.id}`}
                        last={i + 1 === tasks?.new?.length}
                        onMouseOver={() => setHovered(i)}
                        onMouseLeave={() => setHovered(null)}
                      >
                        <TaskInfo>
                          <TaskTitle>{n?.title}</TaskTitle>
                          <TaskContent>{n?.content}</TaskContent>
                        </TaskInfo>
                        {hovered === i && (
                          <TaskAction style={{ width: "100%" }}>
                            <TaskActionIcon onClick={() => complete(n?._id)}>
                              <FiCheck />
                            </TaskActionIcon>
                            <TaskActionIcon gray onClick={() => del(n?._id)}>
                              <FiTrash2 />
                            </TaskActionIcon>
                          </TaskAction>
                        )}
                      </TaskItem>
                    ))}
                </TasksContainer>
              </BoxContent>
            </Box>
          </Col4>
        </Row>
      </Container>
      <Loader show={loading} />
    </>
  );
}

export default Dashboard;
