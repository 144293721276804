import styled from "styled-components";

import { DropdownList } from "react-widgets";

import "~/styles/css/react-widgets.css";

export const Container = styled.div`
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
`;

export const Label = styled.label`
  font-size: 12px;
  font-weight: 600;
  color: ${(props) => (!props.validation ? "#da1e1e" : "#333333")};
`;

export const Error = styled.span`
  color: #da1e1e;
  font-size: 11px;
`;

export const DropInput = styled(DropdownList)`
  background: rgba(255, 255, 255, 0.7);
  border-bottom: ${(props) =>
    !props.validation
      ? "1px solid #da1e1e"
      : `1px solid ${(e) => e.theme?.primaryColor}`};
  padding: 10px 5px;
  color: #333;
  width: 100%;
  margin-top: 0;
  margin-bottom: 3px;
  height: 40px !important;

  && .rw-filter-input {
    color: ${(e) => e.theme?.primaryColor};
    padding: 5;
    margin: 0;
    border: none;
    box-shadow: none;
    background: none;
    background-image: none;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }

  .rw-btn {
    position: relative;
    color: ${(e) => e.theme?.primaryColor};
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    border: 1px solid transparent;
    cursor: pointer;
    outline: none;
  }

  .rw-btn-select {
    color: ${(props) =>
      !props.validation ? "#da1e1e" : `${(e) => e.theme?.primaryColor}`};
    opacity: 0.7;
    transition: all 0.4s;
    margin-right: -8px;
  }

  .rw-widget-container {
    background-color: transparent;
    border: none;
    transition: all 0.4s;
    cursor: pointer;
    opacity: 1;
    margin-top: -9px;
    margin-left: -5px;
  }
`;
