import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import language from "./language.json";

import Container from "~/components/Container";
import Input from "~/components/Input";
import Loader from "~/components/Loader";
import Icon from "./Icon";
import Logo from "./Logo";

import {
  Row,
  Col6,
  Box,
  BoxTitle,
  BoxContent,
  BoxFooter,
  BtnPrimary,
  Title,
  FormGroup,
} from "~/styles/elements";

import { UploadContainer } from "./styles";

import { isEmail, clearPhone } from "~/libraries/utils";

import {
  // getAgencyRequest,
  updateAgencyRequest,
  setTheme,
} from "~/store/modules/profile/actions";

import api from "~/services/api";

function Agency() {
  const dispatch = useDispatch();

  const {
    profile: { lang, agency },
    loading,
    theme,
  } = useSelector((state) => state.profile);

  const [name, setName] = useState(agency?.name);
  const [nameValidation, setNameValidation] = useState(true);

  const [mail, setMail] = useState(agency?.mail);
  const [mailValidation, setMailValidation] = useState(true);

  const [phone, setPhone] = useState(agency?.phone);
  const [phoneValidation, setPhoneValidation] = useState(true);

  const [imgLoading, setImgLoading] = useState(false);

  const upload = async (e) => {
    setImgLoading(true);
    const data = new FormData();
    data.append("file", e.target.files[0]);
    const {
      data: { file },
    } = await api.post("v2/agencies/upload", data);
    setImgLoading(false);
    return file;
  };

  const [icon, setIcon] = useState(theme?.icon);

  const handleIcon = async (e) => {
    const file = await upload(e);

    if (file) {
      setIcon(file);
    }
  };

  const [logo, setLogo] = useState(theme?.logo);
  const [fullLogo, setFullLogo] = useState(agency?.logo);

  const handleLogo = async (e) => {
    const file = await upload(e);

    if (file) {
      setLogo(file);
    }

    if (!fullLogo) {
      setFullLogo(file);
    }
  };

  const handleFullLogo = async (e) => {
    const file = await upload(e);

    if (file) {
      setFullLogo(file);
    }
    if (!logo) {
      setLogo(file);
    }
  };

  const validateAgency = () => {
    let validated = true;
    if (!name) {
      setNameValidation(false);
      validated = false;
    }
    if (!mail || !isEmail(mail)) {
      setMailValidation(false);
      validated = false;
    }
    if (!phone || clearPhone(phone).length < 10) {
      setPhoneValidation(false);
      validated = false;
    }

    return validated;
  };

  const save = () => {
    setNameValidation(true);
    setMailValidation(true);
    setPhoneValidation(true);
    const validated = validateAgency();
    if (validated) {
      dispatch(updateAgencyRequest({ name, mail, phone, logo: fullLogo }));
    }
  };

  const [primary_color, setPrimary] = useState(
    theme?.primary_color || "#5271c4"
  );
  const [primaryValidation, setPrimaryValidation] = useState(true);

  const [secondary_color, setSecondary] = useState(
    theme?.secondary_color || "#333333"
  );
  const [secondaryValidation, setSecondaryValidation] = useState(true);

  const changeTheme = () => {
    setPrimaryValidation(true);
    setSecondaryValidation(true);
    dispatch(setTheme({ primary_color, secondary_color, icon, logo }));
  };

  return (
    <Container>
      <Row>
        <Col6>
          <Box>
            <BoxTitle>
              <Title>{language[lang]?.agency?.title}</Title>
            </BoxTitle>
            <BoxContent>
              <FormGroup>
                <Logo
                  onChange={(e) => handleFullLogo(e)}
                  preview={fullLogo?.path}
                />
                <Input
                  placeholder={language[lang]?.agency?.form?.name?.placeholder}
                  label={language[lang]?.agency?.form?.name?.placeholder}
                  validation={nameValidation}
                  validationText={language[lang]?.agency?.form?.name?.error}
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
                <Input
                  placeholder={language[lang]?.agency?.form?.mail?.placeholder}
                  label={language[lang]?.agency?.form?.mail?.placeholder}
                  validation={mailValidation}
                  validationText={language[lang]?.agency?.form?.mail?.error}
                  onChange={(e) => setMail(e.target.value)}
                  value={mail}
                />
                <Input
                  mask="(99) 9999-9999"
                  placeholder={language[lang]?.agency?.form?.phone?.placeholder}
                  label={language[lang]?.agency?.form?.phone?.placeholder}
                  validation={phoneValidation}
                  validationText={language[lang]?.agency?.form?.phone?.error}
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                />
                <Input
                  mask="99.999.999/9999-99"
                  placeholder={language[lang]?.agency?.form?.cnpj?.placeholder}
                  label={language[lang]?.agency?.form?.cnpj?.placeholder}
                  value={agency?.cnpj}
                  validation
                />
              </FormGroup>
            </BoxContent>
            <BoxFooter>
              <BtnPrimary noMargin onClick={() => save()}>
                {language[lang]?.agency?.btn}
              </BtnPrimary>
            </BoxFooter>
          </Box>
        </Col6>
        <Col6>
          <Box>
            <BoxTitle>
              <Title>{language[lang]?.theme?.title}</Title>
            </BoxTitle>
            <BoxContent>
              <FormGroup>
                <UploadContainer>
                  <Icon onChange={(e) => handleIcon(e)} preview={icon?.path} />
                  <Logo onChange={(e) => handleLogo(e)} preview={logo?.path} />
                </UploadContainer>
                <Input
                  mask="#******"
                  placeholder={
                    language[lang]?.theme?.form?.primary?.placeholder
                  }
                  label={language[lang]?.theme?.form?.primary?.label}
                  validation={primaryValidation}
                  validationText={language[lang]?.theme?.form?.primary?.error}
                  onChange={(e) => setPrimary(e.target.value)}
                  value={primary_color}
                />
                <Input
                  mask="#******"
                  placeholder={
                    language[lang]?.theme?.form?.secondary?.placeholder
                  }
                  label={language[lang]?.theme?.form?.secondary?.label}
                  validation={secondaryValidation}
                  validationText={language[lang]?.theme?.form?.secondary?.error}
                  onChange={(e) => setSecondary(e.target.value)}
                  value={secondary_color}
                />
              </FormGroup>
            </BoxContent>

            <BoxFooter>
              <BtnPrimary noMargin onClick={() => changeTheme()}>
                {language[lang]?.theme?.btn}
              </BtnPrimary>
            </BoxFooter>
          </Box>
        </Col6>
      </Row>
      <Loader show={loading || imgLoading} />
    </Container>
  );
}

export default Agency;
