import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import {
  FiHome,
  FiUsers,
  FiMap,
  FiSliders,
  FaChevronDown,
  FaChevronUp,
  FiBook,
} from "react-icons/all";
import language from "./language.json";

import LogoFull from "~/assets/img/logo-exploreit-branco.png";
import LogoIcon from "~/assets/img/exploreit-icon-branco.png";
import DefaultProfile from "~/assets/img/user.png";

import {
  TopContainer,
  Container,
  SideContainer,
  UserArea,
  User,
  ProfilePhoto,
  Name,
  Chevron,
  Logo,
  Nav,
  NavItem,
  PageIcon,
  PageName,
  MenuUser,
  MenuUserLink,
  SubNavIcon,
  MenuArea,
  SubNavItem,
} from "./styles";

import { logout } from "~/store/modules/auth/actions";

import MenuLang from "./components/LangMenu";
import TaskMenu from "./components/TaskMenu";

function Menu({ children }) {
  const dispatch = useDispatch();

  const [expandedLogo, setExpandedLogo] = useState(false);

  const page = window.location.pathname.split("/");

  const LogOut = () => dispatch(logout());

  const [viewMenuUser, setViewMenuUser] = useState(false);

  const reload = (pagina) => {
    if (page[1] === pagina) {
      window.location.reload();
    }
  };

  const [showSettings, setShowSettings] = useState(false);
  const [showLibraries, setShowLibraries] = useState(false);

  const ResetMenu = () => {
    setShowSettings(false);
    setExpandedLogo(false);
  };

  const CloseAllSubMenu = () => {
    setShowSettings(false);
    setShowLibraries(false);
  };

  const {
    profile: { name, lang, avatar, role },
    theme,
  } = useSelector((state) => state.profile);

  const [icon, setIcon] = useState(LogoIcon);
  const [logo, setLogo] = useState(LogoFull);

  useEffect(() => {
    if (theme?.icon || theme?.logo) {
      setIcon(theme?.icon?.path);
      setLogo(theme?.logo?.path);
    } else {
      setIcon(LogoIcon);
      setLogo(LogoFull);
    }
  }, [theme]);

  return (
    <>
      <TopContainer>
        <UserArea>
          <MenuArea>
            <TaskMenu />
            <MenuLang />
          </MenuArea>
          <User onClick={() => setViewMenuUser(!viewMenuUser)}>
            <ProfilePhoto src={avatar?.path || DefaultProfile} />
            <Name>{name}</Name>
            <Chevron>
              {!viewMenuUser ? <FaChevronDown /> : <FaChevronUp />}
            </Chevron>
            {viewMenuUser && (
              <MenuUser>
                <Link to="/settings">
                  <MenuUserLink>{language[lang].settings}</MenuUserLink>
                </Link>
                <MenuUserLink onClick={() => LogOut()}>
                  {language[lang].logout}
                </MenuUserLink>
              </MenuUser>
            )}
          </User>
        </UserArea>
      </TopContainer>
      <SideContainer
        onMouseOver={() => setExpandedLogo(true)}
        onMouseLeave={() => {
          CloseAllSubMenu();
          ResetMenu();
        }}
      >
        <Logo src={expandedLogo ? logo : icon} />
        <Nav>
          <Link to="/dashboard" onClick={() => reload("dashboard")}>
            <NavItem className={page[1] === "dashboard" ? "active" : ""}>
              <PageIcon>
                <FiHome />
              </PageIcon>
              <PageName>{language[lang].dashboard}</PageName>
            </NavItem>
          </Link>
          <Link to="/clients" onClick={() => reload("clients")}>
            <NavItem className={page[1] === "clients" ? "active" : ""}>
              <PageIcon>
                <FiUsers />
              </PageIcon>
              <PageName>{language[lang].clients}</PageName>
            </NavItem>
          </Link>
          <Link to="/travels" onClick={() => reload("travels")}>
            <NavItem className={page[1] === "travels" ? "active" : ""}>
              <PageIcon>
                <FiMap />
              </PageIcon>
              <PageName>{language[lang].travels}</PageName>
            </NavItem>
          </Link>
          <NavItem
            onClick={() => setShowLibraries(!showLibraries)}
            className={page[1] === "libraries" ? "active" : ""}
          >
            <PageIcon>
              <FiBook />
            </PageIcon>
            <PageName>
              {language[lang].library}
              <SubNavIcon>
                {showLibraries ? <FaChevronUp /> : <FaChevronDown />}
              </SubNavIcon>
            </PageName>
          </NavItem>

          {showLibraries && (
            <>
              <Link
                to="/hotels"
                onClick={() => reload("hotels")}
                onMouseOver={() => setShowLibraries(true)}
              >
                <SubNavItem>
                  <PageName>{language[lang].hotels}</PageName>
                </SubNavItem>
              </Link>
            </>
          )}

          <NavItem
            onClick={() => setShowSettings(!showSettings)}
            className={page[1] === "settings" ? "active" : ""}
          >
            <PageIcon>
              <FiSliders />
            </PageIcon>
            <PageName>
              {language[lang].adjustments}
              <SubNavIcon>
                {showSettings ? <FaChevronUp /> : <FaChevronDown />}
              </SubNavIcon>
            </PageName>
          </NavItem>

          {showSettings && (
            <>
              {role?.id === 2 && (
                <Link to="/agency" onClick={() => reload("agency")}>
                  <SubNavItem>
                    <PageName>{language[lang].agency}</PageName>
                  </SubNavItem>
                </Link>
              )}
              {role?.id <= 3 && (
                <Link to="/users" onClick={() => reload("users")}>
                  <SubNavItem>
                    <PageName>{language[lang].users}</PageName>
                  </SubNavItem>
                </Link>
              )}
              <Link to="/settings" onClick={() => reload("settings")}>
                <SubNavItem>
                  <PageName>{language[lang].settings}</PageName>
                </SubNavItem>
              </Link>
            </>
          )}
        </Nav>
      </SideContainer>
      <Container>{children}</Container>
    </>
  );
}

Menu.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Menu;
