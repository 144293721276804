import produce from "immer";

const INITIAL_STATE = {
  profile: null,
  loading: false,
  theme: null,
};

export default (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case "@auth/signIn_SUCCESS": {
        draft.profile = action.payload.user;
        draft.theme = action.payload.user.theme;
        break;
      }
      case "@agency/theme_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@agency/update_REQUEST": {
        draft.loading = true;
        draft.profile = {
          ...draft.profile,
          agency: { ...draft.profile?.agency, ...action.payload },
        };

        break;
      }
      case "@agency/get_SUCCESS": {
        draft.loading = false;
        if (action?.payload?.theme) draft.theme = { ...action.payload.theme };

        break;
      }
      case "@auth/logout": {
        draft.profile = null;
        break;
      }
      case "@profile/update_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@profile/update_SUCCESS": {
        draft.loading = false;
        draft.profile = {
          ...draft.profile,
          ...action.payload.user,
          lang: action.payload.user.id_language === 1 ? "br" : "en",
        };
        break;
      }
      case "@profile/change_pass_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@profile/change_pass_SUCCESS": {
        draft.loading = false;
        break;
      }
      case "@profile/lang": {
        draft.profile = {
          ...draft.profile,
          lang: action.payload.lang,
        };
        break;
      }
      case "@profile/error": {
        draft.loading = false;
        break;
      }
      default:
        break;
    }
  });
