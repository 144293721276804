import React from "react";
import PropTypes from "prop-types";
import DefaultLogo from "~/assets/img/logo-exploreit-branco.png";

import { Container } from "./styles";

export default function Logo({ onChange, preview }) {
  return (
    <Container>
      <label htmlFor="logo">
        <img src={preview || DefaultLogo} alt="" />

        <input type="file" id="logo" accept="image/*" onChange={onChange} />
      </label>
    </Container>
  );
}

Logo.defaultProps = {
  preview: "",
};

Logo.propTypes = {
  onChange: PropTypes.func.isRequired,
  preview: PropTypes.string,
};
