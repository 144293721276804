import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import language from "./language.json";

import Container from "~/components/Container";
import Input from "~/components/Input";
import DropDown from "~/components/DropDown";
import Loader from "~/components/Loader";
import Avatar from "./Avatar";

import {
  Row,
  Col6,
  Box,
  BoxTitle,
  BoxContent,
  BoxFooter,
  BtnPrimary,
  Title,
  FormGroup,
} from "~/styles/elements";

import { isEmail, clearPhone } from "~/libraries/utils";

import {
  updateUserRequest,
  changePasswordRequest,
} from "~/store/modules/profile/actions";

import api from "~/services/api";

function Config() {
  const dispatch = useDispatch();

  const { profile, loading } = useSelector((state) => state.profile);

  const { lang } = profile;

  const [name, setName] = useState(profile?.name);
  const [nameValidation, setNameValidation] = useState(true);

  const [mail, setMail] = useState(profile?.mail);
  const [mailValidation, setMailValidation] = useState(true);

  const [phone, setPhone] = useState(profile?.phone);
  const [phoneValidation, setPhoneValidation] = useState(true);

  const [id_avatar, setAvatar] = useState(profile?.avatar?.id);

  const [preview, setPreview] = useState(profile?.avatar?.path);

  const [imgLoading, setImgLoading] = useState(false);

  const handleChange = async (e) => {
    setImgLoading(true);
    const data = new FormData();
    data.append("file", e.target.files[0]);

    const { data: response } = await api.post("v2/agencies/upload", data);

    const { id, path } = response?.file;

    setAvatar(id);
    setPreview(path);
    setImgLoading(false);
  };

  const languages = [
    {
      id: 1,
      name: "Português - BR",
    },
    {
      id: 2,
      name: "English - USA",
    },
  ];

  const [userLanguage, setUserLanguage] = useState(lang === "en" ? 2 : 1);

  const [langValidation, setLangValidation] = useState(true);

  const validateProfile = () => {
    let validated = true;
    if (!name) {
      setNameValidation(false);
      validated = false;
    }
    if (!mail || !isEmail(mail)) {
      setMailValidation(false);
      validated = false;
    }
    if (!phone || clearPhone(phone).length < 10) {
      setPhoneValidation(false);
      validated = false;
    }
    if (!userLanguage) {
      setLangValidation(false);
      validated = false;
    }

    return validated;
  };

  const saveProfile = () => {
    setNameValidation(true);
    setMailValidation(true);
    setPhoneValidation(true);
    setLangValidation(true);
    const validated = validateProfile();
    if (validated) {
      dispatch(
        updateUserRequest({
          name,
          mail,
          phone,
          id_language: userLanguage,
          id_avatar,
        })
      );
    }
  };

  const [pass, setPass] = useState("");
  const [passValidation, setPassValidation] = useState(true);
  const [newPass, setNewPass] = useState("");
  const [newPassValidation, setNewPassValidation] = useState(true);
  const [confirmPass, setConfirmPass] = useState("");
  const [confirmPassValidation, setConfirmPassValidation] = useState(true);

  const validatePassword = () => {
    let validated = true;
    if (!pass) {
      setPassValidation(false);
      validated = false;
    }
    if (!newPass || !isEmail(mail)) {
      setNewPassValidation(false);
      validated = false;
    }
    if (!confirmPass || confirmPass !== newPass) {
      setConfirmPassValidation(false);
      validated = false;
    }

    return validated;
  };

  const changePassword = () => {
    setPassValidation(true);
    setNewPassValidation(true);
    setConfirmPassValidation(true);
    const validated = validatePassword();
    if (validated) {
      dispatch(changePasswordRequest({ pass, newPass }));
      setPass("");
      setNewPass("");
      setConfirmPass("");
    }
  };

  return (
    <Container>
      <Row>
        <Col6>
          <Box>
            <BoxTitle>
              <Title>{language[lang]?.profile?.title}</Title>
            </BoxTitle>
            <BoxContent>
              <FormGroup>
                <Avatar onChange={(e) => handleChange(e)} preview={preview} />
                <Input
                  placeholder={language[lang]?.profile?.form?.name?.placeholder}
                  label={language[lang]?.profile?.form?.name?.placeholder}
                  validation={nameValidation}
                  validationText={language[lang]?.profile?.form?.name?.error}
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
                <Input
                  placeholder={language[lang]?.profile?.form?.mail?.placeholder}
                  label={language[lang]?.profile?.form?.mail?.placeholder}
                  validation={mailValidation}
                  validationText={language[lang]?.profile?.form?.mail?.error}
                  onChange={(e) => setMail(e.target.value)}
                  value={mail}
                />
                <Input
                  mask="(99) 9 9999-9999"
                  placeholder={
                    language[lang]?.profile?.form?.phone?.placeholder
                  }
                  label={language[lang]?.profile?.form?.phone?.placeholder}
                  validation={phoneValidation}
                  validationText={language[lang]?.profile?.form?.phone?.error}
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                />
                <DropDown
                  placeholder={
                    language[lang]?.profile?.form?.language?.placeholder
                  }
                  data={languages}
                  label={language[lang]?.profile?.form?.language?.placeholder}
                  validation={langValidation}
                  validationText={
                    language[lang]?.profile?.form?.language?.error
                  }
                  emptyText={language[lang]?.profile?.form?.language?.empty}
                  onChange={(e) => setUserLanguage(e.id)}
                  defaultValue={userLanguage}
                />
              </FormGroup>
            </BoxContent>
            <BoxFooter>
              <BtnPrimary noMargin onClick={() => saveProfile()}>
                {language[lang]?.profile?.btn}
              </BtnPrimary>
            </BoxFooter>
          </Box>
        </Col6>
        <Col6>
          <Box>
            <BoxTitle>
              <Title>{language[lang]?.password?.title}</Title>
            </BoxTitle>
            <BoxContent>
              <FormGroup>
                <Input
                  placeholder={
                    language[lang]?.password?.form?.password?.placeholder
                  }
                  label={language[lang]?.password?.form?.password?.placeholder}
                  validation={passValidation}
                  validationText={
                    language[lang]?.password?.form?.password?.error
                  }
                  onChange={(e) => setPass(e.target.value)}
                  value={pass}
                />
                <Input
                  placeholder={
                    language[lang]?.password?.form?.newPassword?.placeholder
                  }
                  label={
                    language[lang]?.password?.form?.newPassword?.placeholder
                  }
                  validation={newPassValidation}
                  validationText={
                    language[lang]?.password?.form?.newPassword?.error
                  }
                  onChange={(e) => setNewPass(e.target.value)}
                  value={newPass}
                />
                <Input
                  placeholder={
                    language[lang]?.password?.form?.confirmPassword?.placeholder
                  }
                  label={
                    language[lang]?.password?.form?.confirmPassword?.placeholder
                  }
                  validation={confirmPassValidation}
                  validationText={
                    language[lang]?.password?.form?.confirmPassword?.error
                  }
                  onChange={(e) => setConfirmPass(e.target.value)}
                  value={confirmPass}
                />
              </FormGroup>
            </BoxContent>

            <BoxFooter>
              <BtnPrimary noMargin onClick={() => changePassword()}>
                {language[lang]?.password?.btn}
              </BtnPrimary>
            </BoxFooter>
          </Box>
        </Col6>
      </Row>
      <Loader show={loading || imgLoading} />
    </Container>
  );
}

export default Config;
