import styled from "styled-components";

export const Container = styled.div``;

export const Image = styled.img`
  width: 100%;
  height: 200px;
`;

export const Slide = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  z-index: 0;
`;

export const Prev = styled.span`
  width: 20px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  transition: all 0.4s;
  z-index: 5;
  :hover {
    background: #5271c44d;
  }
  & svg {
    margin-top: 5px;
  }
`;

export const Next = styled.span`
  width: 20px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  transition: all 0.4s;
  z-index: 5;
  :hover {
    background: #5271c44d;
  }
  & svg {
    margin-top: 5px;
  }
`;
