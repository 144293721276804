import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  FaPlus,
  FaEllipsisH,
  FiEdit3,
  FiEye,
  FiTrash2,
  FiX,
} from "react-icons/all";

import {
  getClientsRequest,
  storeClientRequest,
  updateClientRequest,
  deleteClientRequest,
  searchClientRequest,
} from "~/store/modules/clients/actions";

import Container from "~/components/Container";
import { InfoItem } from "~/components/SideModal/styles";
import {
  Col5,
  Col12,
  Box,
  BoxContent,
  BoxTitle,
  Icon,
  BtnPrimaryCircle,
  Title,
  Table,
  TBody,
  Th,
  Tr,
  Td,
  Row,
  MenuActions,
  BoxFooter,
  SideContent,
  SideContentContainer,
  CloseBtn,
  SideContentHeader,
  ListTitle,
  ListItem,
  Strong,
  SideContentMenu,
  BtnCircle,
  NoData,
  Text,
  Label,
} from "~/styles/elements";

import { ProfileImg } from "~/pages/Settings/Users/styles";

import SearchBar from "~/components/SearchBar";
import Modal from "~/components/Modal";
import Input from "~/components/Input";
import Alert from "~/components/Alert";
import Pagination from "~/components/Pagination";
import Loader from "~/components/Loader";
import DropDown from "~/components/DropDown";

import {
  isEmail,
  clearPhone,
  fullDate,
  getAge,
  getDate,
} from "~/libraries/utils";

import DefaultProfile from "~/assets/img/user.png";

import language from "./language.json";

function Clients() {
  const dispatch = useDispatch();

  const { clients, pages, loading } = useSelector((state) => state.clients);

  const { lang } = useSelector((state) => state.profile.profile);

  const [page, setPage] = useState(1);

  const [query, setQuery] = useState("");

  const search = (queryterm, querypage = 1) => {
    dispatch(searchClientRequest(queryterm, querypage));
  };

  useEffect(() => {
    if (!query) {
      dispatch(getClientsRequest(page));
    } else {
      search(query, page);
    }
  }, [page]);

  const [name, setName] = useState("");
  const [nameValidation, setNameValidation] = useState(true);
  const [mail, setMail] = useState("");
  const [mailValidation, setMailValidation] = useState(true);
  const [phone, setPhone] = useState("");
  const [phoneValidation, setPhoneValidation] = useState(true);
  const [rg, setRg] = useState("");
  const [rgValidation, setRgValidation] = useState(true);
  const [cpf, setCpf] = useState(null);
  const [cpfValidation, setCpfValidation] = useState(true);
  const [birth_date, setBirthDate] = useState("");
  const [birthDateValidation, setBirthDateValidation] = useState(true);
  const [id_language, setLang] = useState(null);
  const [langValidation, setLangValidation] = useState(true);

  const [client, setClient] = useState(null);
  const [showClient, setShowClient] = useState(false);
  const [showSideModal, setShowSideModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showMore, setShowMore] = useState(null);

  const languages = [
    {
      id: 1,
      name: "Português - BR",
    },
    {
      id: 2,
      name: "English - USA",
    },
  ];

  useEffect(() => {
    if (showClient || showDelete || showSideModal) {
      setShowMore(false);
    }

    if (showClient) {
      setShowSideModal(false);
    }
  }, [showClient, showDelete, showSideModal]);

  const More = (item) => {
    if (showMore === item) {
      setShowMore(null);
    } else {
      setShowMore(item);
    }
  };

  useEffect(() => {
    if (!showClient && !showSideModal && !showDelete) {
      setClient(null);
      setName("");
      setNameValidation(true);
      setMail("");
      setMailValidation(true);
      setPhone("");
      setPhoneValidation(true);
      setRg("");
      setRgValidation(true);
      setCpf(null);
      setCpfValidation(true);
      setBirthDate("");
      setBirthDateValidation(true);
      setLangValidation(null);
      setLangValidation(true);
    }
  }, [showClient, showSideModal, showDelete]);

  const View = (i) => {
    setClient(clients[i]);
    setShowSideModal(true);
  };

  useEffect(() => {
    if (client) {
      setName(client?.name);
      setMail(client?.mail);
      setPhone(client?.phone);
      setCpf(client?.cpf);
      setRg(client?.rg);
      if (client?.birth_date) {
        setBirthDate(getDate(client?.birth_date, "br"));
      }
      setLang(client?.lang?.id);
    }
  }, [client]);

  const Validate = () => {
    let validated = true;

    if (!name) {
      setNameValidation(false);
      validated = false;
    }

    if (!mail || !isEmail(mail)) {
      setMailValidation(false);
      validated = false;
    }

    if (!phone || clearPhone(phone).length < 10) {
      setPhoneValidation(false);
      validated = false;
    }

    if (lang === "br" && !cpf) {
      setCpfValidation(false);
      validated = false;
    }

    if (!rg) {
      setRgValidation(false);
      validated = false;
    }

    if (!birth_date) {
      setBirthDateValidation(false);
      validated = false;
    }

    if (!id_language) {
      setLangValidation(false);
      validated = false;
    }

    return validated;
  };

  const SaveOrUpdate = (id) => {
    setNameValidation(true);
    setMailValidation(true);
    setPhoneValidation(true);
    setCpfValidation(true);
    setRgValidation(true);
    setBirthDateValidation(true);
    setLangValidation(true);

    const validated = Validate();

    if (validated) {
      dispatch(
        !client
          ? storeClientRequest(
              name,
              mail,
              phone,
              cpf,
              rg,
              birth_date,
              id_language
            )
          : updateClientRequest(
              id,
              name,
              mail,
              phone,
              cpf,
              rg,
              birth_date,
              id_language
            )
      );
      setShowClient(false);
    }
  };

  const Edit = (i) => {
    setClient(clients[i]);
    setShowClient(true);
  };

  const PreDelete = (i = null) => {
    setClient(clients[i]);
    setShowMore(null);
    setShowSideModal(false);
    setShowDelete(true);
  };

  const Del = (confirm) => {
    if (confirm) dispatch(deleteClientRequest(client?.id));
  };

  useEffect(() => {
    if (lang === "en") {
      setCpf(null);
      setRg("");
    }
  }, [lang]);

  return (
    <Container>
      <Row>
        <Col12>
          <Box>
            <BoxTitle>
              <Title>{language[lang].title.clients}</Title>
              <Col5 isInput>
                <SearchBar
                  placeholder={language[lang].input.search}
                  onSearch={search}
                  onClear={() => {
                    setQuery("");
                    dispatch(getClientsRequest(1));
                  }}
                  onChange={setQuery}
                />
                <BtnPrimaryCircle onClick={() => setShowClient(true)}>
                  <FaPlus />
                </BtnPrimaryCircle>
              </Col5>
            </BoxTitle>
            <BoxContent>
              <Table>
                <Tr>
                  <Th>{language[lang].table.name}</Th>
                  <Th>{language[lang].table.mail}</Th>
                  <Th>{language[lang].table.phone}</Th>
                  <Th>{language[lang].table.id}</Th>
                  <Th>{language[lang].table.currentTravels}</Th>
                  <Th />
                </Tr>
                <TBody>
                  {clients?.length > 0 ? (
                    clients?.map((a, i) => (
                      <Tr key={`client-${a?.id}`}>
                        <Td>{a?.name}</Td>
                        <Td>{a?.mail}</Td>
                        <Td>{a?.phone}</Td>
                        <Td>{a?.rg}</Td>
                        <Td>{a?.completed_travels}</Td>
                        <Td className="actions">
                          <BtnPrimaryCircle isGrey onClick={() => More(i)}>
                            <FaEllipsisH />
                          </BtnPrimaryCircle>
                          {showMore === i && (
                            <MenuActions>
                              <Icon onClick={() => Edit(i)}>
                                <FiEdit3 />
                              </Icon>
                              <Icon onClick={() => View(i)}>
                                <FiEye />
                              </Icon>
                              <Icon
                                className="last"
                                onClick={() => PreDelete(i)}
                              >
                                <FiTrash2 />
                              </Icon>
                            </MenuActions>
                          )}
                        </Td>
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan="5">
                        {loading ? (
                          <NoData>{language[lang].loading}</NoData>
                        ) : (
                          <NoData>{language[lang].noData}</NoData>
                        )}
                      </Td>
                    </Tr>
                  )}
                </TBody>
              </Table>
            </BoxContent>
            <BoxFooter right noBorder>
              <Pagination page={page} pages={pages} onPaginate={setPage} />
            </BoxFooter>
          </Box>
        </Col12>
      </Row>
      <Modal
        open={showClient}
        modalTitle={
          !client
            ? language[lang].title.newClient
            : language[lang].title.editClient
        }
        onCancel={() => setShowClient(false)}
        cancelText={language[lang].button.cancel}
        confirmText={language[lang].button.save}
        onConfirm={() => SaveOrUpdate(client?.id)}
      >
        <Col5>
          <Input
            placeholder={language[lang].input.placeholder.name}
            label={language[lang].input.label.name}
            validationText={language[lang].validation.name}
            validation={nameValidation}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            placeholder={language[lang].input.placeholder.mail}
            label={language[lang].input.label.mail}
            validation={mailValidation}
            validationText={language[lang].validation.mail}
            value={mail}
            onChange={(e) => setMail(e.target.value)}
          />
          <Input
            mask="(99) 9 9999-9999"
            placeholder={language[lang].input.placeholder.phone}
            label={language[lang].input.label.phone}
            validation={phoneValidation}
            validationText={language[lang].validation.phone}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          {lang === "br" ? (
            <>
              <Input
                mask="999.999.999-99"
                placeholder={language[lang].input.placeholder.cpf}
                label={language[lang].input.label.cpf}
                validation={cpfValidation}
                validationText={language[lang].validation.cpf}
                value={cpf}
                onChange={(e) => setCpf(e.target.value)}
              />
              <Input
                mask="99.999.999-9"
                placeholder={language[lang].input.placeholder.id}
                label={language[lang].input.label.id}
                validation={rgValidation}
                validationText={language[lang].validation.id}
                value={rg}
                onChange={(e) => setRg(e.target.value)}
              />
            </>
          ) : (
            <Input
              placeholder={language[lang].input.placeholder.id}
              label={language[lang].input.label.id}
              validation={rgValidation}
              validationText={language[lang].validation.id}
              value={rg}
              onChange={(e) => setRg(e.target.value)}
            />
          )}
          <Input
            mask="99/99/9999"
            placeholder={language[lang].input.placeholder.birthDate}
            label={language[lang].input.label.birthDate}
            validation={birthDateValidation}
            validationText={language[lang].validation.birthDate}
            value={birth_date}
            onChange={(e) => setBirthDate(e.target.value)}
          />
          {id_language && (
            <DropDown
              placeholder={language[lang].input.placeholder.language}
              label={language[lang].input.label.language}
              data={languages}
              onChange={(e) => setLang(e.id)}
              validation={langValidation}
              validationText={language[lang].validation.lang}
              defaultValue={id_language}
            />
          )}
          {!id_language && (
            <DropDown
              placeholder={language[lang].input.placeholder.language}
              label={language[lang].input.label.language}
              data={languages}
              onChange={(e) => setLang(e.id)}
              validation={langValidation}
              validationText={language[lang].validation.lang}
            />
          )}
        </Col5>
        <Col5 style={{ marginLeft: 120 }}>
          <Label>{language[lang].addUser}</Label>
          <Text style={{ margin: "10px 0px" }}>
            {language[lang].addUserSub}
          </Text>
          <DropDown
            placeholder={language[lang].input.placeholder.travel}
            label={language[lang].input.label.travel}
          />
        </Col5>
      </Modal>
      <Alert
        open={showDelete}
        onClose={() => setShowDelete(false)}
        onClick={(value) => Del(value)}
        title={language[lang].alert.title}
        text={language[lang].alert.delete}
        type="error"
        buttons={[
          {
            text: language[lang].button.delete,
            closeModal: true,
            value: true,
          },
          {
            text: language[lang].button.cancel,
            gray: true,
            closeModal: true,
            value: false,
          },
        ]}
      />
      {showSideModal && (
        <SideContent>
          <CloseBtn onClick={() => setShowSideModal(false)}>
            <FiX />
          </CloseBtn>
          <SideContentHeader>
            <ProfileImg src={client?.avatar || DefaultProfile} />
            <ListTitle>{client?.name}</ListTitle>
          </SideContentHeader>
          <SideContentContainer>
            <ListItem>
              <Strong>{language[lang]?.sideModal?.email}:</Strong>{" "}
              {client?.mail}
            </ListItem>
            <ListItem>
              <Strong>{language[lang]?.sideModal?.phone}:</Strong>{" "}
              {client?.phone}
            </ListItem>
            {lang === "br" && (
              <ListItem>
                <Strong>{language[lang]?.sideModal?.cpf}:</Strong> {client?.cpf}
              </ListItem>
            )}
            <ListItem>
              <Strong>{language[lang]?.sideModal?.id}:</Strong> {client?.rg}
            </ListItem>
            {client?.birth_date && (
              <ListItem>
                <Strong>{language[lang]?.sideModal?.birth}:</Strong>{" "}
                {getDate(client?.birth_date, lang)}
              </ListItem>
            )}
            {client?.birth_date && (
              <ListItem>
                <Strong>{language[lang]?.sideModal?.age}:</Strong>{" "}
                {getAge(client?.birth_date, lang)}
              </ListItem>
            )}
            {client?.last_access && (
              <ListItem>
                <Strong>{language[lang]?.sideModal?.lastAccess}:</Strong>{" "}
                {fullDate(client?.last_access, lang)}
              </ListItem>
            )}
            <ListItem>
              <Strong>{language[lang]?.sideModal?.lang}:</Strong>{" "}
              {client?.lang?.name}
            </ListItem>
            <ListItem>
              <Strong>{language[lang]?.sideModal?.currentTravels}:</Strong>{" "}
              {client?.completed_travels}
            </ListItem>
            <ListItem>
              <Strong>{language[lang]?.sideModal?.futureTravels}:</Strong>{" "}
              {client?.future_travels}
            </ListItem>
            {client?.travel_in_progress && (
              <ListItem style={{ marginTop: 30 }}>
                <Strong>{language[lang]?.sideModal?.progressTravels}:</Strong>
                <SideContentContainer style={{ padding: " 10px 0px" }}>
                  <InfoItem>{client?.travel_in_progress?.name}</InfoItem>
                  <ListItem style={{ fontSize: 11 }}>
                    {client?.travel_in_progress?.destinations[0]?.name}
                    {client?.travel_in_progress?.destinations?.length > 1 &&
                      ` + ${
                        client?.travel_in_progress?.destinations?.length - 1
                      } ${lang === "br" ? "destinos" : "destinations"}`}
                  </ListItem>
                </SideContentContainer>
              </ListItem>
            )}
          </SideContentContainer>
          <SideContentMenu>
            <BtnCircle onClick={() => setShowClient(true)}>
              <FiEdit3 />
            </BtnCircle>
            <BtnCircle onClick={() => setShowDelete(true)}>
              <FiTrash2 />
            </BtnCircle>
          </SideContentMenu>
        </SideContent>
      )}
      <Loader show={loading} />
    </Container>
  );
}

export default Clients;
