import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 5px;
  label {
    width: 120px;
    height: 120px;
    background: transparent;
    border: 1px solid #5271c44d;
    border-radius: 7px;
    cursor: pointer;
    transition: all 0.4s;
    position: relative;
    font-size: 50px;
    color: #5271c44d;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      opacity: 0.7;
    }
    &:hover span {
      opacity: 1;
    }
    img {
      height: 100%;
      width: auto;
      background: transparent;
      border: 1px solid #5271c44d;
      border-radius: 7px;
      cursor: pointer;
      transition: all 0.4s;
    }
    span {
      position: absolute;
      opacity: 0;
      z-index: 10;
      font-size: 24px;
      color: #5271c4;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffffff99;
      width: 100%;
      height: 100%;
    }
    svg {
      font-size: 60px;
      color: #5271c4;
    }
    input {
      display: none;
    }
  }
`;
