export function updateUserRequest({
  name,
  mail,
  phone,
  id_language,
  id_avatar,
}) {
  return {
    type: "@profile/update_REQUEST",
    payload: { name, mail, phone, id_language, id_avatar },
  };
}

export function updateUserSuccess({ user }) {
  return {
    type: "@profile/update_SUCCESS",
    payload: { user },
  };
}

export function getAgencyRequest() {
  return {
    type: "@agency/get_REQUEST",
  };
}
export function getAgencySuccess(theme) {
  return {
    type: "@agency/get_SUCCESS",
    payload: { theme },
  };
}

export function updateAgencyRequest({ name, mail, phone, logo }) {
  return {
    type: "@agency/update_REQUEST",
    payload: { name, mail, phone, logo },
  };
}

export function updateAgencySuccess() {
  return {
    type: "@agency/update_SUCCESS",
  };
}

export function setTheme({ primary_color, secondary_color, icon, logo }) {
  return {
    type: "@agency/theme_REQUEST",
    payload: { primary_color, secondary_color, icon, logo },
  };
}

export function changePasswordRequest({ pass, newPass }) {
  return {
    type: "@profile/change_pass_REQUEST",
    payload: { pass, newPass },
  };
}

export function changePasswordSuccess() {
  return {
    type: "@profile/change_pass_SUCCESS",
  };
}

export function changeLang(lang) {
  return {
    type: "@profile/lang",
    payload: { lang },
  };
}

export function userError() {
  return {
    type: "@profile/error",
  };
}
