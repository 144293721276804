import styled from "styled-components";

import { FiStar } from "react-icons/fi";

export const Container = styled.span`
  font-size: 22px;
  margin-right: 5px;
  color: #5271c4;
  cursor: pointer;
  && svg {
    margin-bottom: -3px;
  }
`;

export const RateContent = styled.div`
  display: flex;
`;

export const Label = styled.label`
  font-size: 12px;
  font-weight: 600;
  color: ${(props) => (!props.validation ? "#da1e1e" : "#333333")};
`;

export const Error = styled.span`
  color: #da1e1e;
  font-size: 11px;
`;

export const RateCursor = styled.span`
  cursor: pointer;
`;

export const RateIcon = styled(FiStar)`
  width: 1.2em;
  font-size: 20px;
  color: ${(props) => (!props.validation ? "#da1e1e" : "#5271c4")};
`;
