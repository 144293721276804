import produce from "immer";

const INITIAL_STATE = {
  data: [],
};

export default (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case "@dashboard/getInfo_REQUEST": {
        draft.loading = true;
        break;
      }
      case "@dashboard/getInfo_SUCCESS": {
        draft.data = action.payload;
        draft.loading = false;
        break;
      }
      case "@dashboard/ERROR": {
        draft.loading = false;
        break;
      }
      default:
        break;
    }
  });
