export function getClientsRequest(page = 1) {
  return {
    type: "@clients/get_REQUEST",
    payload: { page },
  };
}

export function storeClientRequest(
  name,
  mail,
  phone,
  cpf,
  rg,
  birth_date,
  id_language
) {
  return {
    type: "@clients/store_REQUEST",
    payload: { name, mail, phone, cpf, rg, birth_date, id_language },
  };
}

export function updateClientRequest(
  id,
  name,
  mail,
  phone,
  cpf,
  rg,
  birth_date,
  id_language
) {
  return {
    type: "@clients/update_REQUEST",
    payload: { id, name, mail, phone, cpf, rg, birth_date, id_language },
  };
}

export function deleteClientRequest(id) {
  return {
    type: "@clients/delete_REQUEST",
    payload: { id },
  };
}

export function searchClientRequest(queryterm, querypage = 1) {
  return {
    type: "@clients/search_REQUEST",
    payload: { queryterm, querypage },
  };
}

export function clientSuccess({ clients, total, pages }) {
  return {
    type: "@clients/SUCCESS",
    payload: { clients, total, pages },
  };
}

export function clientsFailure() {
  return {
    type: "@clients/ERROR",
  };
}
