import React from "react";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";

import Routes from "./routes";
import { store, persistor } from "./store";
import history from "./services/history";

import "react-toastify/dist/ReactToastify.css";

import GlobalStyle from "./styles/global";

import ThemeProvider from "./ThemeProvider";

export default () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ThemeProvider>
        <GlobalStyle />
        <Router history={history}>
          <ToastContainer autoClose={3000} />
          <Routes />
        </Router>
      </ThemeProvider>
    </PersistGate>
  </Provider>
);
