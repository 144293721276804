import React from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import PropTypes from "prop-types";

import { Container, BtnIcon, PageInfo, Page } from "./styles";

function Pagination({ page, pages, onPaginate }) {
  const next = () => {
    if (page < pages) {
      onPaginate(page + 1);
    }
  };

  const prev = () => {
    if (page > 1) {
      onPaginate(page - 1);
    }
  };

  return (
    <Container>
      <BtnIcon onClick={() => prev()} visible={!(page < 2)}>
        <FiChevronLeft />
      </BtnIcon>
      <PageInfo>
        <Page>{page}</Page>/{pages}
      </PageInfo>
      <BtnIcon onClick={() => next()} visible={!(page >= pages)}>
        <FiChevronRight />
      </BtnIcon>
    </Container>
  );
}

Pagination.defaultProps = {
  page: 1,
  pages: 1,
  onPaginate: () => {},
};

Pagination.propTypes = {
  page: PropTypes.number,
  pages: PropTypes.number,
  onPaginate: PropTypes.func,
};

export default Pagination;
