import React from "react";

import PropTypes from "prop-types";

import { Container, CheckIcon, UnCheckIcon } from "./styles";

function CheckBox({ checked, label, onClick }) {
  return (
    <Container>
      {checked ? (
        <CheckIcon onClick={onClick} />
      ) : (
        <UnCheckIcon onClick={onClick} />
      )}
      {label}
    </Container>
  );
}

CheckBox.defaultProps = {
  label: "",
  checked: false,
  onClick: () => {},
};

CheckBox.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
};

export default CheckBox;
