import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Label, Error, RateCursor, RateIcon, RateContent } from "./styles";

const Rate = ({ label, validation, validationText, onRate }) => {
  function StarIcon(fillProps) {
    const { fill = "none" } = fillProps;
    return <RateIcon fill={fill} validation={validation} />;
  }

  const RatingIcon = (rateProps) => {
    const {
      index,
      rating,
      hoverRating,
      onMouseEnter,
      onMouseLeave,
      onSaveRating,
    } = rateProps;

    const fill = useMemo(() => {
      if (hoverRating >= index) {
        return validation ? "#5271c4" : "#da1e1e";
      }
      if (!hoverRating && rating >= index) {
        return validation ? "#5271c4" : "#da1e1e";
      }

      return "none";
    }, [rating, hoverRating, index]);

    return (
      <RateCursor
        onMouseEnter={() => onMouseEnter(index)}
        onMouseLeave={() => onMouseLeave()}
        onClick={() => onSaveRating(index)}
      >
        <StarIcon fill={fill} />
      </RateCursor>
    );
  };

  const [hoverRating, setHoverRating] = useState(0);
  const onMouseEnter = (index) => {
    setHoverRating(index);
  };
  const onMouseLeave = () => {
    setHoverRating(0);
  };
  const onSaveRating = (index) => {
    onRate(index);
  };

  return (
    <>
      {label && <Label validation={validation}>{label}</Label>}
      <RateContent>
        {[1, 2, 3, 4, 5].map((index) => (
          <RatingIcon
            index={index}
            rating={onRate}
            hoverRating={hoverRating}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onSaveRating={onSaveRating}
          />
        ))}
      </RateContent>
      {!validation && <Error>{validationText}</Error>}
    </>
  );
};

Rate.defaultProps = {
  label: "",
  validation: true,
  validationText: "",
  onRate: () => {},
};

Rate.propTypes = {
  label: PropTypes.string,
  validation: PropTypes.bool,
  validationText: PropTypes.string,
  onRate: PropTypes.func,
};

export default Rate;
