import styled from "styled-components";

export const Box = styled.div`
  position: absolute;
  background: #eaeaea;
  width: 330px;
  height: 100%;
  top: 0;
  right: 0px;
  -webkit-box-shadow: -2px 2px 11px -4px rgb(0 0 0 / 75%);
  -moz-box-shadow: -2px 2px 11px -4px rgba(0, 0, 0, 0.75);
  box-shadow: -2px 2px 11px -4px rgb(0 0 0 / 75%);
  padding: 0px;
  z-index: 1;
  overflow-y: auto;
`;

export const BoxContent = styled.div`
  padding: 40px;
  justify-content: center;
  align-items: center;
  text-align-last: center;
`;

export const ProfilePhoto = styled.img`
  border-radius: 50px;
  width: 75px;
  height: 75px;
  text-align: center;
`;

export const ProfileName = styled.h4`
  color: #333;
  font-size: 17px;
  font-family: "Lato", sans-serif;
  margin-top: 20px;
`;

export const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`;

export const InfoLabel = styled.label`
  font-size: 13px;
  font-weight: bold;
  margin: 8px 0px;
  display: ${(props) => (!props.isUser ? "flex" : "grid")};
  font-family: "Lato", sans-serif;
  text-align-last: ${(props) => props.isUser && "left"};
`;

export const InfoItem = styled.span`
  color: ${(e) => e.theme?.primaryColor};
`;

export const InfoText = styled.p`
  font-size: 13px;
  font-weight: normal;
  font-family: "Lato", sans-serif;
`;

export const BoxButton = styled.div`
  display: flex;
  width: 100%;
`;
