/* eslint-disable import/no-cycle */
import { call, put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

import api from "~/services/api";
import { hotelsFailure, hotelsSuccess } from "./actions";

export function* get() {
  // export function* get({ payload: { page } }) {
  try {
    // const { data } = yield call(api.get, `/v2/agencies/hotels/${page}`);
    const data = {
      hotels: [
        {
          name: "Ibis Hotel Copacabana",
          about:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          address: "R. Min. Viveiros de Castro, 134 - Copacabana",
          city: "Rio de Janeiro - RJ",
          rooms: [
            {
              name: "Suíte master",
              capacity: 2,
            },
            {
              name: "Suíte master",
              capacity: 2,
            },
            {
              name: "Suíte master",
              capacity: 2,
            },
          ],
          phone: "(11) 9999-9999",
          rating: 1,
          channels: {
            site: "https://www.google.com.br",
            facebook: "https://pt-br.facebook.com/",
            instagram: "https://www.instagram.com",
          },
          createdDate: "2021-03-08 21:47:21",
        },
        {
          name: "Ibis Hotel Copacabana",
          about:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          address: "R. Min. Viveiros de Castro, 134 - Copacabana",
          rooms: [
            {
              name: "Suíte master",
              capacity: 2,
            },
            {
              name: "Suíte master",
              capacity: 2,
            },
            {
              name: "Suíte master",
              capacity: 2,
            },
          ],
          city: "Rio de Janeiro - RJ",
          phone: "(11) 9 9999-9999",
          rating: 0,
          channels: {
            site: "https://www.google.com.br",
            facebook: "https://pt-br.facebook.com/",
            instagram: "https://www.instagram.com",
          },
          createdDate: "2021-03-08 21:47:21",
        },
        {
          name: "Ibis Hotel Copacabana",
          about:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          address: "R. Min. Viveiros de Castro, 134 - Copacabana",
          city: "Rio de Janeiro - RJ",
          rooms: [
            {
              name: "Suíte master",
              capacity: 2,
            },
            {
              name: "Suíte master",
              capacity: 2,
            },
            {
              name: "Suíte master",
              capacity: 2,
            },
          ],
          phone: "(11) 9 9999-9999",
          rating: 3,
          channels: {
            site: "https://www.google.com.br",
            facebook: "https://pt-br.facebook.com/",
            instagram: "https://www.instagram.com",
          },
          createdDate: "2021-03-08 21:47:21",
        },
      ],
    };
    if (data.error) throw new Error(data.error);

    yield put(hotelsSuccess(data));
  } catch (e) {
    toast.error(e.message);
    yield put(hotelsFailure());
  }
}

export function* store({ payload }) {
  try {
    const { data } = yield call(api.post, "/v2/agencies/hotels", payload);
    if (data.error) throw new Error(data.error);

    yield put(hotelsSuccess(data));

    toast.success("Hotel successfully created!");
  } catch (e) {
    toast.error(e.message);
    yield put(hotelsFailure());
  }
}

export function* update({ payload }) {
  try {
    const { data } = yield call(api.put, "/v2/agencies/hotels", payload);
    if (data.error) throw new Error(data.error);

    yield put(hotelsSuccess(data));

    toast.success("Hotel successfully updated!");
  } catch (e) {
    toast.error(e.message);
    yield put(hotelsFailure());
  }
}

export function* del({ payload: { id } }) {
  try {
    const { data } = yield call(api.delete, `/v2/agencies/hotels/${id}`);
    if (data.error) throw new Error(data.error);

    yield put(hotelsSuccess(data));

    toast.success("Hotel successfully deleted!");
  } catch (e) {
    toast.error(e.message);
    yield put(hotelsFailure());
  }
}

export function* search({ payload: { queryterm, querypage } }) {
  try {
    const { data } = yield call(
      api.get,
      `/v2/agencies/hotels/search/${queryterm}/${querypage}`
    );
    if (data.error) throw new Error(data.error);

    yield put(hotelsSuccess(data));
  } catch (e) {
    toast.error(e.message);
    yield put(hotelsFailure());
  }
}

export default all([
  takeLatest("@hotels/get", get),
  takeLatest("@hotels/store", store),
  takeLatest("@hotels/update", update),
  takeLatest("@hotels/delete", del),
  takeLatest("@hotels/search", search),
]);
