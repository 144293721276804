export function signInRequest(mail, pass) {
  return {
    type: "@auth/signIn_REQUEST",
    payload: { mail, pass },
  };
}

export function signInSuccess({ user, token }) {
  return {
    type: "@auth/signIn_SUCCESS",
    payload: { user, token },
  };
}

export function logout() {
  return {
    type: "@auth/logout",
  };
}

export function authFailure() {
  return {
    type: "@auth/error",
  };
}
