export function getListRequest(page = 1) {
  return {
    type: "@travels/get_list_REQUEST",
    payload: { page },
  };
}

export function getListSuccess(payload) {
  return {
    type: "@travels/get_list_SUCCESS",
    payload,
  };
}

export function saveNewTravelRequest(
  name,
  mail,
  phone,
  cpf,
  rg,
  birth_date,
  id_language
) {
  return {
    type: "@travels/save_travel_REQUEST",
    payload: { name, mail, phone, cpf, rg, birth_date, id_language },
  };
}

export function saveNewTravelSuccess() {
  return {
    type: "@travels/save_travel_SUCCESS",
  };
}

export function updateTravelRequest(
  id,
  name,
  mail,
  phone,
  cpf,
  rg,
  birth_date,
  id_language
) {
  return {
    type: "@travels/update_travel_REQUEST",
    payload: { id, name, mail, phone, cpf, rg, birth_date, id_language },
  };
}

export function updateTravelSuccess() {
  return {
    type: "@travels/update_travel_SUCCESS",
  };
}

export function deleteTravelRequest(id) {
  return {
    type: "@travels/delete_travel_REQUEST",
    payload: { id },
  };
}

export function deleteTravelSuccess() {
  return {
    type: "@travels/delete_travel_SUCCESS",
  };
}

export function searchTravelRequest(queryterm, querypage = 1) {
  return {
    type: "@travels/search_travel_REQUEST",
    payload: { queryterm, querypage },
  };
}

export function searchTravelSuccess(payload) {
  return {
    type: "@travels/search_travel_SUCCESS",
    payload,
  };
}

export function travelsFailure() {
  return {
    type: "@travels/ERROR",
  };
}
