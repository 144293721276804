/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { FiEdit, FiPlus, FiCheck, FiTrash2 } from "react-icons/fi";

import {
  Container,
  Icon,
  ContentContainer,
  TaskItem,
  TaskInfo,
  TaskTitle,
  TaskContent,
  TaskAction,
  NoTasks,
  PlusBtn,
  TasksContainer,
  NewTaskContainer,
  Pin,
  TaskActionIcon,
} from "./styles";

import { TabMenu, Tab, BtnPrimary } from "~/styles/elements";

import Input from "~/components/Input";

import {
  getTasksRequest,
  storeTaskRequest,
  updateTaskRequest,
  deleteTaskRequest,
  endTaskRequest,
} from "~/store/modules/tasks/actions";

function TaskMenu() {
  const dispatch = useDispatch();

  const { lang } = useSelector((state) => state.profile.profile);
  const { tasks } = useSelector((state) => state.tasks);

  const [task, setTask] = useState(null);

  useEffect(() => {
    dispatch(getTasksRequest());
  }, []);

  const [showTasks, setShowTasks] = useState(false);

  const [read, setRead] = useState(false);
  const [edit, setEdit] = useState(false);

  const [title, setTitle] = useState("");
  const [titleValidation, setTitleValidation] = useState(true);

  const [content, setContent] = useState("");
  const [contentValidation, setContentValidation] = useState(true);

  const [hovered, setHovered] = useState(null);

  const validate = () => {
    setTitleValidation(true);
    setContentValidation(true);

    let validated = true;

    if (!title) {
      setTitleValidation(false);
      validated = false;
    }
    if (!content) {
      setContentValidation(false);
      validated = false;
    }

    return validated;
  };

  const editTask = (i) => {
    setTask(tasks?.new[i]);
    setTitle(tasks?.new[i]?.title);
    setContent(tasks?.new[i]?.content);
    setEdit(true);
  };

  const del = (id) => {
    dispatch(deleteTaskRequest(id));
  };

  const complete = (id) => {
    dispatch(endTaskRequest(id));
  };

  const save = () => {
    const validated = validate();
    if (validated) {
      if (task) {
        dispatch(updateTaskRequest({ title, content, id: task?._id }));
      } else {
        dispatch(storeTaskRequest({ title, content }));
      }
      setEdit(false);
      setTitle("");
      setContent("");
      setTask(null);
    }
  };

  return (
    <Container>
      <Icon style={{ height: 60 }} onClick={() => setShowTasks(!showTasks)}>
        <FiEdit />
        {tasks?.new?.length > 0 && <Pin>{tasks?.new?.length}</Pin>}
      </Icon>
      {showTasks && (
        <ContentContainer>
          <PlusBtn onClick={() => setEdit(!edit)}>
            <FiPlus />
          </PlusBtn>
          <TabMenu>
            <Tab active={!read} onClick={() => setRead(false)}>
              {lang === "br" ? "Pendentes" : "Pending"}
            </Tab>
            <Tab active={read} onClick={() => setRead(true)}>
              {lang === "br" ? "Concluídas" : "Completed"}
            </Tab>
          </TabMenu>
          <TasksContainer>
            {!read &&
              (tasks?.new?.length > 0 ? (
                tasks?.new?.map((n, i) => (
                  <TaskItem
                    key={`new-task-${n?.id}`}
                    last={i + 1 === tasks?.new?.length}
                    onMouseOver={() => setHovered(i)}
                    onMouseLeave={() => setHovered(null)}
                  >
                    <TaskInfo>
                      <TaskTitle>{n?.title}</TaskTitle>
                      <TaskContent>{n?.content}</TaskContent>
                    </TaskInfo>
                    {hovered === i && (
                      <TaskAction>
                        <TaskActionIcon onClick={() => complete(n?._id)}>
                          <FiCheck />
                        </TaskActionIcon>
                        <TaskActionIcon gray onClick={() => editTask(i)}>
                          <FiEdit />
                        </TaskActionIcon>
                        <TaskActionIcon gray onClick={() => del(n?._id)}>
                          <FiTrash2 />
                        </TaskActionIcon>
                      </TaskAction>
                    )}
                  </TaskItem>
                ))
              ) : (
                <NoTasks>
                  {lang === "br" ? "Nada aqui ainda" : "Taskhing here yet"}
                </NoTasks>
              ))}
            {read &&
              (tasks?.completed?.length > 0 ? (
                tasks?.completed?.map((n) => (
                  <TaskItem key={`read-task-${n?.id}`} last>
                    <TaskInfo>
                      <TaskTitle>{n?.title}</TaskTitle>
                      <TaskContent>{n?.content}</TaskContent>
                    </TaskInfo>
                  </TaskItem>
                ))
              ) : (
                <NoTasks>
                  {lang === "br" ? "Nada aqui ainda" : "Taskhing here yet"}
                </NoTasks>
              ))}
          </TasksContainer>
          {edit && (
            <NewTaskContainer>
              <Input
                placeholder={lang === "br" ? "Título da tarefa" : "Task title"}
                // label={lang === "br" ? "Título da tarefa" : "Task title"}
                validation={titleValidation}
                validationText={
                  lang === "br"
                    ? "Informe o titulo da tarela"
                    : "Insert the task title"
                }
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                isBoxed
                style={{ marginBottom: 0, width: "100%" }}
              />
              <Input
                placeholder={
                  lang === "br" ? "Descrição da tarefa" : "Task content"
                }
                // label={lang === "br" ? "Descrição da tarefa" : "Task content"}
                validation={contentValidation}
                validationText={
                  lang === "br"
                    ? "Informe o conteúdo da tarela"
                    : "Insert the task content"
                }
                onChange={(e) => setContent(e.target.value)}
                value={content}
                isBoxed
                style={{ width: "100%" }}
              />
              <BtnPrimary onClick={() => save()} noMargin>
                {lang === "br" ? "Salvar" : "Save"}
              </BtnPrimary>
            </NewTaskContainer>
          )}
        </ContentContainer>
      )}
    </Container>
  );
}

export default TaskMenu;
