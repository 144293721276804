import React from "react";
import { InputBoxed, BtnPrimary } from "~/styles/elements";

import {
  BgAuth,
  Container,
  Title,
  BgFormAuth,
  BgLogoAuth,
  Label,
  BtnAuth,
} from "./styles";

function ForgotPassword() {
  return (
    <Container>
      <BgAuth>
        <Title colorPrimary>Seja bem-vindo</Title>
        <Label colorPrimary>Faça login para continuar</Label>
        <BgLogoAuth />
      </BgAuth>
      <BgFormAuth>
        <Title noBold lowMargin>
          Login
        </Title>
        <Label noBold margin>
          Insira seus dados de acesso para continuar
        </Label>
        <InputBoxed placeholder="Seu melhor e-mail" type="mail" />
        <BtnPrimary>Enviar</BtnPrimary>
        <BtnAuth href="/">Cancelar</BtnAuth>
      </BgFormAuth>
    </Container>
  );
}

export default ForgotPassword;
