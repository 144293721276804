export function getHotels(page = 1) {
  return {
    type: "@hotels/get",
    payload: { page },
  };
}

export function saveNewHotel(hotel) {
  return {
    type: "@hotels/store",
    payload: hotel,
  };
}

export function updateHotel(hotel) {
  return {
    type: "@hotels/update",
    payload: hotel,
  };
}

export function deleteHotel(id) {
  return {
    type: "@hotels/delete",
    payload: { id },
  };
}

export function searchHotel(queryterm, querypage = 1) {
  return {
    type: "@hotels/search",
    payload: { queryterm, querypage },
  };
}

export function hotelsSuccess({ hotels, total, pages }) {
  return {
    type: "@hotels/SUCCESS",
    payload: { hotels, total, pages },
  };
}

export function hotelsFailure() {
  return {
    type: "@hotels/ERROR",
  };
}
