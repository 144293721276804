import styled from "styled-components";
import InputMask from "react-input-mask";

export const Container = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

export const Content = styled.div`
  width: 100%;
  height: 40px;
  margin-bottom: 3px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const FormContainer = styled.div`
  width: calc(100% - 82px);
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const InputBoxed = styled.input`
  background: rgba(255, 255, 255, 0.7);
  border: ${(props) =>
    !props.validation ? "1px solid #da1e1e" : "1px solid #5271c4"};
  border-radius: 5px;
  padding: 10px;
  color: #333;
  width: 38%;
  height: 40px !important;
  display: flex;
`;

export const InputBoxedMasked = styled(InputMask)`
  width: 25%;
  height: 40px;
  border: ${(props) =>
    !props.validation ? "1px solid #da1e1e" : "1px solid #5271c4"};
  border-radius: 5px;
  padding: 5px;
  color: #333;
  margin-bottom: 3px;
`;

export const Label = styled.label`
  font-size: 12px;
  font-weight: 600;
  color: ${(props) => (!props.validation ? "#da1e1e" : "#333333")};
`;

export const Error = styled.span`
  color: #da1e1e;
  font-size: 11px;
`;

export const At = styled.span`
  width: 4%;
  text-align: center;
`;
