import React, { useState } from "react";
import { FiSearch } from "react-icons/fi";
import PropTypes from "prop-types";

import { Container, Input, Icon } from "./styles";

function SearchBar({ placeholder, onSearch, onClear, onChange }) {
  const [text, setText] = useState(null);

  const search = () => {
    if (text) {
      onChange(text);
      onSearch(text);
    } else {
      onClear();
    }
  };

  const handleKeySearch = (e) => {
    if (e === "Enter") search();
  };

  return (
    <Container>
      <Input
        placeholder={placeholder}
        onChange={(e) => setText(e.target.value)}
        value={text}
        onKeyPress={(e) => handleKeySearch(e.key)}
      />
      <Icon onClick={() => search()}>
        <FiSearch />
      </Icon>
    </Container>
  );
}

SearchBar.defaultProps = {
  onClear: () => {},
  onChange: () => {},
};

SearchBar.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  onChange: PropTypes.func,
};

export default SearchBar;
