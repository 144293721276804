import styled from "styled-components";

export const Container = styled.div`
  width: 300px;
  background: #eaeaea4d;
  border: 1px dashed ${(e) => e?.theme?.primaryColor}4d;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-bottom: 30px;
  border-radius: 7px;
  margin-top: 15px;
  overflow: hidden;
  label {
    cursor: pointer;
    transition: all 0.4s;
    overflow: hidden;
    &:hover {
      opacity: 0.7;
    }
    img {
      height: auto;
      width: 98%;
      border-radius: 7px;
      border: 3px solid 5271c44d;
    }
    input {
      display: none;
    }
  }
`;
