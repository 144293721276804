/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { FiCheck, FiTrash2, FiX, FiPlus } from "react-icons/fi";

import language from "./language.json";

import {
  Container,
  Content,
  FormContainer,
  InputBoxed,
  InputBoxedMasked,
  At,
  Error,
} from "./styles";
import { BtnPrimaryCircle } from "~/styles/elements";

function DestinationSelect({ data, onEdit, onDelete, onAdd, validation }) {
  const { lang } = useSelector((state) => state.profile.profile);

  const [city, setCity] = useState("");
  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");
  const [edited, setEdited] = useState(false);

  const [cityValidation, setCityValidation] = useState(true);
  const [startDateValidation, setStartDateValidation] = useState(true);
  const [endDateValidation, setEndDateValidation] = useState(true);

  useEffect(() => {
    if (!validation) {
      setCityValidation(false);
      setStartDateValidation(false);
      setEndDateValidation(false);
    }
  }, [validation]);

  const Validate = () => {
    let validated = true;

    if (!city) {
      setCityValidation(false);
      validated = false;
    }

    if (!start_date) {
      setStartDateValidation(false);
      validated = false;
    }
    if (!end_date) {
      setEndDateValidation(false);
      validated = false;
    }

    return validated;
  };

  const Cancel = () => {
    setEdited(false);
    setCity(data?.city || "");
    setStartDate(data?.start_date || "");
    setEndDate(data?.end_date || "");
  };

  const Save = () => {
    setCityValidation(true);
    setStartDateValidation(true);
    setEndDateValidation(true);

    const validated = Validate();

    if (validated) {
      onAdd({ city, start_date, end_date });
      Cancel();
    }
  };

  useEffect(() => {
    if (data) {
      setCity(data?.city);
      setStartDate(data?.start_date);
      setEndDate(data?.end_date);
    }
  }, [data]);

  return (
    <Container>
      <Content>
        <FormContainer>
          <InputBoxed
            value={city}
            onChange={(e) => {
              setEdited(true);
              setCity(e.target.value);
            }}
            placeholder={language[lang].placeholder.city}
            validation={cityValidation}
          />
          <InputBoxedMasked
            mask="99/99/9999"
            value={start_date}
            onChange={(e) => {
              setEdited(true);
              setStartDate(e.target.value);
            }}
            placeholder={language[lang].placeholder.startDate}
            validation={startDateValidation}
          />
          <At>{language[lang].at}</At>
          <InputBoxedMasked
            mask="99/99/9999"
            value={end_date}
            onChange={(e) => {
              setEdited(true);
              setEndDate(e.target.value);
            }}
            placeholder={language[lang].placeholder.endDate}
            validation={endDateValidation}
          />
        </FormContainer>
        {data ? (
          <>
            {!edited ? (
              <>
                <BtnPrimaryCircle
                  style={{ marginLeft: 5 }}
                  onClick={() => onDelete()}
                >
                  <FiTrash2 />
                </BtnPrimaryCircle>
              </>
            ) : (
              <>
                <BtnPrimaryCircle
                  style={{ marginLeft: 5 }}
                  onClick={() => {
                    onEdit({ city, start_date, end_date });
                    setEdited(false);
                  }}
                >
                  <FiCheck />
                </BtnPrimaryCircle>
                <BtnPrimaryCircle
                  style={{ marginLeft: 5 }}
                  onClick={() => Cancel()}
                >
                  <FiX />
                </BtnPrimaryCircle>
              </>
            )}
          </>
        ) : (
          <BtnPrimaryCircle style={{ marginLeft: 5 }} onClick={() => Save()}>
            <FiPlus />
          </BtnPrimaryCircle>
        )}
      </Content>
      {!validation && <Error>{language[lang].validation.noRange}</Error>}
      {(!cityValidation && validation) ||
      (!startDateValidation && validation) ||
      (!endDateValidation && validation) ? (
        <Error>{`${
          lang === "br" ? "Por favor insira a" : "Please insert the"
        } ${
          (!cityValidation && `${language[lang].validation.city}`) ||
          (!startDateValidation && `${language[lang].validation.startDate}`) ||
          (!endDateValidation && `${language[lang].validation.endDate}`)
        }`}</Error>
      ) : (
        ""
      )}
    </Container>
  );
}

DestinationSelect.defaultProps = {
  data: null,
  onEdit: () => {},
  onDelete: () => {},
  onAdd: () => {},
  validation: true,
};

DestinationSelect.propTypes = {
  data: PropTypes.object,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onAdd: PropTypes.func,
  validation: PropTypes.bool,
};

export default DestinationSelect;
