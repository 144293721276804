import styled from "styled-components";

import InputMask from "react-input-mask";

import { DropdownList, DateTimePicker } from "react-widgets";

import { FaStar } from "react-icons/fa";

import CurrencyFormat from "react-currency-format";

import "./css/react-widgets.css";

export const Row = styled.div`
  clear: both;
  display: table;
  width: 100%;
`;

export const Offset1 = styled.div`
  margin-left: 8.33%;
`;
export const Offset2 = styled.div`
  margin-left: 16.66%;
`;
export const Offset3 = styled.div`
  margin-left: 25%;
`;
export const Offset4 = styled.div`
  margin-left: 33.33%;
`;
export const Col1 = styled.div`
  width: 8.33%;
  float: left;
  padding: 15px;
`;
export const Col2 = styled.div`
  width: 16.66%;
  float: left;
  padding: 15px;
`;
export const Col3 = styled.div`
  width: 25%;
  float: left;
  padding: 15px;
`;
export const Col4 = styled.div`
  width: 33.33%;
  float: left;
  padding: 15px;
`;
export const Col5 = styled.div`
  width: 41.66%;
  float: left;
  padding: ${(props) => (props.isInput ? "0px" : "15px")};
  display: ${(props) => props.isInput && "flex"};
  align-items: ${(props) => props.isInput && "center"};
  justify-content: ${(props) => props.isInput && "space-between"};
`;
export const Col6 = styled.div`
  width: 50%;
  float: left;
  padding: 15px;
`;
export const Col7 = styled.div`
  width: 58.33%;
  float: left;
  padding: 15px;
`;
export const Col8 = styled.div`
  width: 66.66%;
  float: left;
  padding: 15px;
`;
export const Col9 = styled.div`
  width: 75%;
  float: left;
  padding: 15px;
`;
export const Col10 = styled.div`
  width: 83.33%;
  float: left;
  padding: 15px;
`;
export const Col11 = styled.div`
  width: 91.66%;
  float: left;
  padding: 15px;
`;
export const Col12 = styled.div`
  width: 100%;
  float: left;
  padding: 15px;
`;
export const Box = styled.div`
  background: #fff;
  width: 100%;
  border-radius: 7px;
  min-height: 50px;
  box-shadow: 0 0 3px -3px ${(e) => e.theme?.secondaryColor};
`;
export const BoxTitle = styled.div`
  padding: 30px 30px 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(51, 51, 51, 0.2);
`;

export const BoxContent = styled.div`
  padding: 15px 10px;
  width: 100%;
`;

export const BoxFooter = styled.div`
  padding: 20px 30px;
  width: 100%;
  display: flex;
  justify-content: ${(props) => (props.right ? "flex-end" : "flex-start")};
  align-items: center;
  border-top: ${(props) =>
    props.noBorder ? "none" : `1px solid ${props.theme?.primaryColor}0d;`};
`;
export const Title = styled.h1`
  font-size: 18px;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  color: ${(e) => e.theme?.primaryColor};
`;

export const Label = styled.label`
  color: ${(e) => e.theme?.primaryColor};
  font-size: 14px;
  font-weight: bold;
`;

export const Span = styled.span`
  color: #0a5d36;
`;

export const BtnPrimary = styled.button`
  border: none;
  background: ${(e) => e.theme?.primaryColor};
  color: #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  border-radius: 7px;
  padding: 10px 50px;
  height: 36px;
  width: 36px;
  cursor: pointer;
  margin-top: ${(props) => (props.noMargin ? "0px" : "20px")};
  transition: all 0.4s;

  :hover {
    opacity: 0.7;
  }
`;

export const BtnNoBox = styled.a`
  color: #eca1fe;
  transition: all 0.4s ease 0s;
  cursor: pointer;
  margin-top: ${(props) => (props.noMargin ? "0px" : "20px")};
  transition: all 0.4s;
  text-transform: uppercase;
  :hover {
    opacity: 0.7;
  }
`;

export const BtnPrimaryCircle = styled.button`
  border: none;
  background: ${(props) =>
    props.isGrey ? "rgba(82, 113, 196, 0.2)" : `${props.theme?.primaryColor}`};
  color: ${(props) =>
    props.isGrey ? `${props.theme?.secondaryColor}` : "#eaeaea"};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  border-radius: 50px;
  padding: 10px;
  height: 36px;
  width: 36px;
  cursor: pointer;
  margin-top: 0px;
  text-transform: uppercase;
  transition: all 0.2s;
  margin: ${(props) => props.isMargin && "5px"};

  :hover {
    opacity: 0.7;
  }
`;

export const BtnPrimaryLg = styled.button`
  border: none;
  background: #0a5d36;
  color: #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: "Lato", sans-serif;
  border-radius: 7px;
  padding: 10px 30px;
  cursor: pointer;
  margin-right: 15px;

  :hover {
    opacity: 0.7;
  }
`;

export const BtnGreen = styled.button`
  border: none;
  background: #7ecb34;
  color: #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: "Lato", sans-serif;
  border-radius: 7px;
  padding: 7px 30px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

export const BtnOut = styled.button`
  border: 1px solid #0a5d36;
  background: transparent;
  color: #0a5d36;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: "Lato", sans-serif;
  border-radius: 7px;
  padding: 7px 15px;
  cursor: pointer;

  :hover {
    background: #0a5d36;
    color: #fff;
  }
`;
export const BtnFilter = styled.button`
  border: 1px solid #7ecb34;
  background: transparent;
  color: #7ecb34;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: "Lato", sans-serif;
  border-radius: 7px;
  padding: 7px 30px;
  cursor: pointer;
  transition: all 0.3s !important;

  && svg {
    font-size: 14px;
    margin-right: 5px;
    transition: all 0.3s !important;
  }
`;

export const BtnFilterSelected = styled.button`
  border: 1px solid #7ecb34;
  background: #7ecb34;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: "Lato", sans-serif;
  border-radius: 7px;
  padding: 7px 30px;
  cursor: pointer;
  transition: all 0.3s !important;

  && svg {
    font-size: 14px;
    margin-right: 5px;
    transition: all 0.3s !important;
  }
`;

export const BtnRed = styled.button`
  border: none;
  background: #da1e1e;
  color: #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: "Lato", sans-serif;
  border-radius: 7px;
  padding: 7px 30px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

export const Icon = styled.span`
  font-size: 22px;
  margin-right: 5px;
  color: ${(e) => e.theme?.primaryColor};
  cursor: pointer;
  && svg {
    margin-bottom: -3px;
  }
`;

export const NoData = styled.h4`
  color: ${(e) => e.theme?.primaryColor}4d;
  font-size: 14px;
  padding: 20px;
`;

export const SubTitle = styled.h3`
  font-size: 16px;
  font-family: "Lato", sans-serif;
  color: ${(e) => e.theme?.secondaryColor}99;
  margin-bottom: 20px;
  margin-left: 5px;
`;

export const SubTitle2 = styled.h4`
  color: ${(e) => e.theme?.secondaryColor};
  font-size: 14px;
  font-family: "Lato", sans-serif;
  margin-bottom: 15px;
  margin-left: 5px;
`;

export const DropDownBoxed = styled(DropdownList)`
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid ${(e) => e.theme?.primaryColor};
  border-radius: 5px;
  padding: 10px;
  color: ${(e) => e.theme?.secondaryColor};
  width: 100%;
  margin-top: 0;
  height: 40px !important;

  && .rw-filter-input {
    color: #0a5d36;
    padding: 0;
    margin: 0;
    border: none;
    box-shadow: none;
    background: none;
    background-image: none;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }

  .rw-btn {
    position: relative;
    color: #0a5d36;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    border: 1px solid transparent;
    cursor: pointer;
    outline: none;
  }

  .rw-btn-select {
    color: #0a5d36;
    opacity: 0.7;
    transition: all 0.4s;
  }

  .rw-widget-container {
    background-color: transparent;
    border: none;
    transition: all 0.4s;
    cursor: pointer;
    opacity: 1;
    margin-top: -9px;
    margin-left: -5px;
  }
`;

export const DropDown = styled(DropdownList)`
  background: rgba(255, 255, 255, 0.7);
  border-bottom: 1px solid ${(e) => e.theme?.primaryColor};
  padding: 10px;
  color: ${(e) => e.theme?.secondaryColor};
  width: 100%;
  margin-top: 0;
  height: 40px !important;

  && .rw-filter-input {
    color: #0a5d36;
    padding: 5;
    margin: 0;
    border: none;
    box-shadow: none;
    background: none;
    background-image: none;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }

  .rw-btn {
    position: relative;
    color: #0a5d36;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    border: 1px solid transparent;
    cursor: pointer;
    outline: none;
  }

  .rw-btn-select {
    color: #0a5d36;
    opacity: 0.7;
    transition: all 0.4s;
  }

  .rw-widget-container {
    background-color: transparent;
    border: none;
    transition: all 0.4s;
    cursor: pointer;
    opacity: 1;
    margin-top: -9px;
    margin-left: -5px;
  }
`;

export const MaskedBoxed = styled(InputMask)`
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid ${(e) => e.theme?.primaryColor};
  border-radius: 5px;
  padding: 10px;
  color: ${(e) => e.theme?.secondaryColor};
  width: 100%;
  height: 40px !important;
`;

export const Masked = styled(InputMask)`
  background: rgba(255, 255, 255, 0.7);
  border: none;
  border-bottom: 1px solid ${(e) => e.theme?.primaryColor}4d;
  padding: 10px;
  color: ${(e) => e.theme?.secondaryColor};
  width: 100%;
  height: 40px !important;
`;

export const Currency = styled(CurrencyFormat)`
  background: rgba(255, 255, 255, 0.7);
  border: none;
  border-bottom: 1px solid ${(e) => e.theme?.secondaryColor}4d;
  padding: 10px;
  color: ${(e) => e.theme?.secondaryColor};
  width: 100%;
  height: 40px !important;
`;

export const CurrencyBoxed = styled(CurrencyFormat)`
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid ${(e) => e.theme?.secondaryColor}4d;
  border-radius: 5px;
  padding: 10px;
  color: ${(e) => e.theme?.secondaryColor};
  width: 100%;
  height: 40px !important;
`;

export const DatePicker = styled(DateTimePicker)`
  background: rgba(255, 255, 255, 0.7);
  border-bottom: 1px solid ${(e) => e.theme?.secondaryColor}4d;
  padding: 10px;
  color: ${(e) => e.theme?.secondaryColor};
  width: 100%;
  margin-top: 0;
  height: 40px !important;

  && .rw-filter-input {
    color: #0a5d36;
    padding: 5;
    margin: 0;
    border: none;
    box-shadow: none;
    background: none;
    background-image: none;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }

  .rw-btn {
    position: relative;
    color: #0a5d36;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    border: 1px solid transparent;
    cursor: pointer;
    outline: none;
  }

  .rw-btn-select {
    color: #0a5d36;
    opacity: 0.7;
    transition: all 0.4s;
  }

  .rw-widget-container {
    background-color: transparent;
    border: none;
    transition: all 0.4s;
    cursor: pointer;
    opacity: 1;
    margin-top: -9px;
    margin-left: -5px;
  }
`;

export const InputBoxed = styled.input`
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid ${(e) => e.theme?.primaryColor}4d;
  border-radius: 5px;
  padding: 10px;
  color: ${(e) => e.theme?.secondaryColor};
  width: 300px;
  height: 40px !important;
  display: flex;
  margin-top: 10px;
`;

export const Input = styled.input`
  background: rgba(255, 255, 255, 0.7);
  border: none;
  border-bottom: 1px solid ${(e) => e.theme?.primaryColor}4d;
  padding: 10px;
  color: ${(e) => e.theme?.secondaryColor};
  width: 100%;
  height: 40px !important;
`;

export const TextAreaBoxed = styled.textarea`
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid ${(e) => e.theme?.secondaryColor}4d;
  border-radius: 5px;
  padding: 10px;
  color: ${(e) => e.theme?.secondaryColor};
  width: 100%;
  height: 120px !important;
`;

export const TextArea = styled.textarea`
  background: rgba(255, 255, 255, 0.7);
  border: none;
  border-bottom: 1px solid ${(e) => e.theme?.secondaryColor}4d;
  padding: 10px;
  color: ${(e) => e.theme?.secondaryColor};
  width: 100%;
  height: 120px !important;
`;

export const InputTitle = styled.p`
  font-weight: bold;
  font-family: "Lato", sans-serif;
  font-size: 10px;
  color: ${(e) => e.theme?.secondaryColor};
`;

export const More = styled.p`
  color: ${(e) => e.theme?.secondaryColor};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

export const Filter = styled.div`
  padding: 0px 15px 0px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  border-bottom: 1px solid rgba(51, 51, 51, 0.2);
`;

export const TabMenu = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
`;

export const Tab = styled.label`
  color: ${(props) =>
    props.active
      ? `${props.theme?.primaryColor}`
      : `${props.theme?.secondaryColor}99`};
  font-family: "Lato", sans-serif;
  width: 100%;
  font-weight: ${(props) => (props.active ? "bold" : "400")};
  font-size: 11px;
  padding: ${(props) => (props.active ? "5px 10px" : "6px 10px")};
  text-align: center;
  margin-bottom: -1px;
  cursor: pointer;
  border-bottom: ${(props) =>
    props.active
      ? `2px solid ${props.theme?.primaryColor}`
      : `1px solid ${props.theme?.secondaryColor}99`};
  transition: all 0.2s;
`;

export const Table = styled.table`
  border-spacing: 0;
  width: 100%;
`;

export const TrTitle = styled.tr`
  height: 60px;

  && .highlight {
    background: #0a5d3625 !important;
  }
`;
export const TBody = styled.tbody`
  height: 300px;
  overflow-y: auto;
`;

export const Tr = styled.tr`
  height: 48px;
  padding: 7px 0px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.07);
  &:last-child td {
    border-bottom: none;
  }

  && .light {
    color: ${(e) => e.theme?.secondaryColor}4d;
    border-bottom: 1px solid r ${(e) => e.theme?.secondaryColor}99;
  }

  td {
    border-bottom: 1px solid ${(e) => e.theme?.primaryColor}0d;
  }

  .light strong {
    color: ${(e) => e.theme?.secondaryColor};
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .highlight {
    background: #0a5d3625 !important;
  }
`;

export const Th = styled.th`
  color: ${(e) => e.theme?.secondaryColor}4d;
  font-family: "Lato", sans-serif;
  font-size: 11px;
  text-align: center;
  padding: 5px;
`;

export const Td = styled.td`
  color: ${(e) => e.theme?.secondaryColor};
  font-family: "Lato", sans-serif;
  font-size: 11px;
  text-align: center;
  padding: 5px;
  font-weight: bold;
  position: relative;
`;

export const MenuActions = styled.div`
  height: 48px;
  padding: 15px 20px;
  border-radius: 15px;
  background: #fff;
  border: 1px solid ${(e) => e.theme?.primaryColor}4d;
  position: absolute;
  right: 60px;
  top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
  min-width: 100px;
  && span {
    margin-right: 15px;
  }
  && .last {
    margin-right: 0px;
  }
`;

export const Small = styled.small`
  text-transform: lowercase;
`;

export const SideContent = styled.div`
  position: fixed;
  top: 60px;
  right: 0;
  bottom: 0;
  height: auto;
  width: 350px;
  background: #eaeaea;
  z-index: 50;
  box-shadow: -3px 0px 3px -2px ${(e) => e.theme?.secondaryColor}4d;
  overflow-y: auto;
`;

export const SideContentHeader = styled.div`
  width: 100%;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

export const SideContentContainer = styled.div`
  max-height: calc(100% - 200px - 80px);
  padding: 15px;
  overflow-y: auto;
`;

export const ListTitle = styled.h2`
  color: ${(e) => e.theme?.secondaryColor};
  font-size: 16px;
  margin-bottom: 20px;
`;

export const ListLabel = styled.h4`
  color: ${(e) => e.theme?.secondaryColor};
  font-size: 12px;
  margin-top: 30px;
  margin-bottom: 7px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  line-height: 18px;
`;

export const ListItem = styled.p`
  color: ${(e) => e.theme?.secondaryColor};
  font-size: 12px;
  margin-bottom: 7px;
  font-family: "Lato", sans-serif;
  line-height: 18px;
`;

export const CloseBtn = styled.span`
  position: absolute;
  color: ${(e) => e.theme?.secondaryColor};
  top: 5px;
  left: 5px;
  font-size: 22px;
  height: 35px;
  width: 35px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s;
  z-index: 10;
  :hover {
    background: ${(e) => e.theme?.secondaryColor}4d;
    border-radius: 50px;
  }
`;

export const Text = styled.p`
  font-size: 12px;
  font-family: "Lato", sans-serif;
  color: ${(e) => e.theme?.secondaryColor};
  line-height: 18px;
  margin-bottom: 10px;
`;

export const SideContentMenu = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  left: 15px;
  bottom: 40px;
`;

export const BtnCircle = styled.span`
  border: 1px solid ${(e) => e.theme?.primaryColor}4d;
  background: #ffffff;
  color: ${(e) => e.theme?.primaryColor};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  border-radius: 50px;
  height: 40px;
  width: 40px;
  cursor: pointer;
  transition: all 0.4s;
  margin-right: 12px;

  :hover {
    opacity: 0.7;
  }
`;

export const Strong = styled.strong``;

export const FormGroup = styled.div`
  padding: 0px 15px;
`;

export const Gallery = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const Link = styled.a``;

export const RateIcon = styled(FaStar)`
  width: 1.5em;
  font-size: 15px;
  color: #5271c4;
`;

export const RateContainer = styled.div``;
