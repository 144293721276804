import React from "react";
import PropTypes from "prop-types";

import { Container, Title, Value } from "./styles";

function Popups({ title, value }) {
  return (
    <Container>
      <Title>{title}</Title>
      <Value>{value}</Value>
    </Container>
  );
}

Popups.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
};

Popups.defaultProps = {
  title: "Viagens",
  value: "0",
};

export default Popups;
