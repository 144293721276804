import styled from "styled-components";

export const Container = styled.div`
  padding: 40px;
  background: #3333334d;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  top: 60px;
  left: 80px;
  bottom: 0;
  right: 0;
  width: auto;
  height: auto;
  z-index: 15;
`;
