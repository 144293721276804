import { DropdownList } from "react-widgets";
import styled from "styled-components";

export const TopContainer = styled.div`
  position: fixed;
  height: 60px;
  top: 0;
  left: 80px;
  right: 0;
  background: #fff;
  width: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0 2px 2px -2px rgba(51, 51, 51, 0.6);
  padding: 0px 50px;
  z-index: 1;
`;

export const MenuArea = styled.div`
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 150px;
`;

export const SideContainer = styled.div`
  position: fixed;
  height: auto;
  top: 0;
  left: 0;
  bottom: 0;
  background: ${(e) => e.theme.primaryColor};
  z-index: 1;
  width: 80px;
  transition: all 0.2s;

  :hover {
    width: 250px;
  }

  :hover p {
    display: block;
  }
`;

export const Container = styled.div`
  position: fixed;
  top: 60px;
  background: #eaeaea;
  height: auto;
  width: auto;
  left: 80px;
  right: 0;
  bottom: 0;
  padding: 20px;
  overflow-y: auto;
`;

export const Billing = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Icon = styled.span`
  color: ${(e) => e.theme?.primaryColor};
  font-size: 24px;
  margin-left: 5px;
`;

export const DropDown = styled(DropdownList)`
  background: rgba(255, 255, 255, 0.7);
  border: none;
  padding: 10px;
  color: ${(e) => e.theme?.secondaryColor};
  width: 350px;
  margin-top: 0px;
  margin-left: 10px;
  height: 40px !important;
  z-index: 1;

  && .rw-filter-input {
    color: ${(e) => e.theme?.primaryColor};
    padding: 0;
    margin: 0;
    border: none;
    box-shadow: none;
    background: none;
    background-image: none;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }

  .rw-btn {
    position: relative;
    color: ${(e) => e.theme?.primaryColor};
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    border: 1px solid transparent;
    cursor: pointer;
    outline: none;
  }

  .rw-btn-select {
    color: ${(e) => e.theme?.primaryColor};
    opacity: 0.7;
    transition: all 0.4s;
  }

  .rw-widget-container {
    background-color: transparent;
    border: none;
    transition: all 0.4s;
    cursor: pointer;
    opacity: 1;
    margin-top: -9px;
    margin-left: -5px;
  }
`;

export const UserArea = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Notifications = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 60px;
  width: 60px;
  margin-right: 15px;
`;

export const NotificationIcon = styled.span`
  font-size: 20px;
  color: ${(e) => e.theme?.primaryColor};
  cursor: pointer;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  background: transparent;
  transition: all 0.2s;

  :hover {
    background: ${(e) => e.theme?.secondaryColor}4d !important;
  }

  && svg {
    margin-top: 3px;
  }
`;

export const NotificationsList = styled.ul`
  position: absolute;
  top: 70px;
  background: #fff;
  border-radius: 7px;
  list-style: none;
`;

export const NotificationItem = styled.li`
  list-style: none;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
  width: 280px;
  padding: 20px;
`;

export const Notificationtitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const NotificationName = styled.h6`
  font-size: 14px;
  color: ${(e) => e.theme?.primaryColor};
`;
export const NotificationDate = styled.span`
  font-size: 10px;
  color: rgba(51, 51, 51, 0.6);
`;

export const NotificationContent = styled.p`
  font-size: 11px;
  color: ${(e) => e.theme?.secondaryColor};
  margin-top: 15px;
`;

export const Lang = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  position: relative;
  height: 60px;
`;

export const LangPhoto = styled.img`
  height: 30px;
  width: 30px;
  border-radius: 50px;
`;

export const MenuLang = styled.ul`
  position: absolute;
  top: 70px;
  list-style: none;
  border-radius: 7px;
`;

export const MenuLangLink = styled.li`
  color: ${(e) => e.theme?.secondaryColor};
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all.4s;
  width: 100%;

  :hover {
    opacity: 0.6;
  }

  &:last-child {
    padding-top: 7px;
  }
`;

export const User = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  position: relative;
  height: 60px;
`;

export const ProfilePhoto = styled.img`
  height: 30px;
  width: 30px;
  border-radius: 50px;
`;

export const Chevron = styled.span`
  font-size: ${(props) => (props.isUser ? "13px" : "12px")};
  color: ${(e) => e.theme?.primaryColor};
  margin-top: ${(props) => props.isUser && "10px"};
`;

export const Name = styled.p`
  margin-left: 15px;
  margin-right: 10px;
  font-size: 16px;
  color: ${(e) => e.theme?.primaryColor};
`;

export const Logo = styled.img`
  height: 45px;
  margin-bottom: 20px;
  margin-left: 25px;
  margin-top: 10px;
`;

export const Nav = styled.ul`
  width: 100%;
  list-style: none;

  .active {
    background: ${(e) => e.theme?.secondaryColor}4d;
  }
`;

export const SubNav = styled.ul`
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  list-style: none;

  .active {
    background: ${(e) => e.theme?.secondaryColor}4d;
  }
`;

export const SubNavIcon = styled.span`
  color: #fff;
  font-size: 16px;
  position: absolute;
  right: 15px;
`;

export const NavLabel = styled.p`
  color: #eaeaea;
  font-size: 10px;
  margin-top: 40px;
  margin-left: 29px;
  margin-bottom: 10px;
  opacity: 0.7;
  font-family: "Lato", sans-serif;
  display: none;
  text-transform: uppercase;
`;

export const NavItem = styled.li`
  width: 100%;
  position: relative;
  font-size: 16px;
  padding: 15px 29px;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  :hover {
    background: ${(e) => e.theme?.secondaryColor}4d;
  }
`;

export const SubNavItem = styled.li`
  width: 100%;
  position: relative;
  font-size: 16px;
  padding: 15px 29px 15px 53px;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  :hover {
    background: ${(e) => e.theme?.secondaryColor}4d;
  }
`;

export const PageIcon = styled.span`
  font-size: 24px;
  color: #fff;
`;

export const PageName = styled.p`
  color: #fff;
  margin-left: 30px;
  transition-delay: display 2s;
  display: none;
  font-size: 14px;
  font-weight: bold;
`;

export const MenuUser = styled.ul`
  position: absolute;
  top: 70px;
  right: -10px;
  width: 150px;
  background: #fff;
  list-style: none;
  border-radius: 7px;

  && :first-child {
    border-bottom: 1px solid rgba(51, 51, 51, 0.3);
  }
`;

export const MenuUserLink = styled.li`
  color: ${(e) => e.theme?.secondaryColor};
  font-size: 12px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all.4s;
  padding: 7px 10px;
  width: 100%;

  :hover {
    opacity: 0.6;
  }
`;
