export function getUsersRequest(page = 1) {
  return {
    type: "@user/get_REQUEST",
    payload: { page },
  };
}

export function getUsersSuccess({ users }) {
  return {
    type: "@user/get_SUCCESS",
    payload: { users },
  };
}

export function deleteUserRequest(id) {
  return {
    type: "@user/delete_REQUEST",
    payload: { id },
  };
}

export function deleteUserSuccess({ users }) {
  return {
    type: "@user/delete_SUCCESS",
    payload: { users },
  };
}

export function searchUsersRequest(query, page) {
  return {
    type: "@user/search_REQUEST",
    payload: { query, page },
  };
}

export function searchUsersSuccess({ users }) {
  return {
    type: "@user/search_SUCCESS",
    payload: { users },
  };
}

export function storeUserRequest({ name, mail, phone, id_role, id_language }) {
  return {
    type: "@user/store_REQUEST",
    payload: { name, mail, phone, id_role, id_language },
  };
}

export function storeUserSuccess({ users }) {
  return {
    type: "@user/store_SUCCESS",
    payload: { users },
  };
}

export function updateUserRequest({
  id = null,
  name,
  mail,
  phone,
  id_role,
  id_language,
}) {
  return {
    type: "@user/update_REQUEST",
    payload: { id, name, mail, phone, id_role, id_language },
  };
}

export function updateUserSuccess({ users }) {
  return {
    type: "@user/update_SUCCESS",
    payload: { users },
  };
}

export function userError() {
  return {
    type: "@user/error",
  };
}
