export function toastUnauthorized() {
  return {
    type: "@toast/unauthorized",
  };
}

export function toastManyRequests() {
  return {
    type: "@toast/many_requests",
  };
}
